import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  pageContainer: {
    minHeight: '100vh',
    display: 'flex',
  },
  langSelectorContainer: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    zIndex: '1000',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    backgroundColor: theme.midnightGreen,
    width: '50%',
    '&.iave': {
      backgroundColor: '#0082D7',
    },
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    position: 'relative',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  cardContainer: {
    width: '100%',
  },
  loginContainer: {
    width: '100%',
    margin: '20px 20%',
    padding: '30px',
    boxShadow: `0 0 5px ${theme.shadowColor}`,
    backgroundColor: theme.backgroundColor,
    borderRadius: '18px',
  },
  iaveLogosContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: '10px',
    width: '100%',
    position: 'absolute',
    bottom: '20px',
  },
  iaveLogo: {
    width: '20%',
  },
  iaveLogoWhite: {
    width: '100%',
  },
  prrLogo: {
    width: '60%',
  },
  loginHeader: {
    paddingTop: '10px',
    marginBottom: '24px',
  },
  loginTitle: {
    fontSize: '24px',
  },
  loginSubtitle: {
    fontSize: '15px',
    color: theme.textColor2,
  },
  loginStrategiesSeparator: {
    marginTop: '20px',
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  },
  separatorLine: {
    width: '100%',
    borderBottom: '1px solid #ddd',
  },
  input: {
    marginTop: '20px',
    width: '100%',
  },
  button: {
    margin: '20px 0',
    marginBottom: '5px',
    border: 'none',
  },
  forgotPassword: {
    margin: '10px auto',
    width: 'fit-content',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  separator: {
    margin: '20px 25%',
    borderBottom: `1px solid ${theme.textColor2}`,
  },
  createAccountContainer: {
    textAlign: 'center',
    fontSize: '14px',
  },
  registerLink: {
    marginLeft: '5px',
    color: '#83cafd',
  },
  passwordWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  showPasswordButton: {
    margin: '40px 0 0 10px',
    cursor: 'pointer',
    position: 'absolute',
    marginRight: '15px',
  },
  intuitivoLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    position: 'absolute',
    bottom: '20px',
  },
  poweredBy: {
    color: 'white',
    fontSize: '12px',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    pageContainer: {
      flexDirection: 'column',
      backgroundColor: theme.midnightGreen,
      '&.iave': {
        backgroundColor: '#0082D7',
      },
    },
    logoContainer: {
      alignItems: 'start',
      width: '100%',
      paddingTop: '7vh',
    },
    contentContainer: {
      width: '100%',
      flexWrap: 'wrap',
    },
    formContainer: {
      width: '100%',
      marginTop: '3vh',
    },
    loginContainer: {
      margin: '20px 10%',
    },
    iaveLogosContainer: {
      position: 'unset',
    },
  },
}));
