import React, { useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { useSelector } from 'react-redux';

import { selectUserCurriculum } from 'actions/userActions';
import lang from 'lang';

import ChangeCurriculumModal from '../ChangeCurriculumModal';
import Button from 'components/common/Button';
import Card from 'components/common/Card';

import useStyles from './styles';

const DangerCard = () => {
  const classes = useStyles();
  const userCurriculum = useSelector(selectUserCurriculum);

  const [changeCurriculumOpen, setChangeCurriculumOpen] = useState(false);

  return (
    <Card
      header={lang.profile.danger.dangerHeader}
      className={classes.card}
    >
      <div className={classes.cardContent}>
        <ChangeCurriculumModal
          open={changeCurriculumOpen}
          close={() => setChangeCurriculumOpen(false)}
        />
        <div>
          <span className={classes.label}>
            {lang.profile.danger.curriculum}
            {': '}
          </span>
          <span>
            {lang.curricula[userCurriculum.name]}
          </span>
        </div>
        <Spacer px={10} />
        <Button
          onClick={() => setChangeCurriculumOpen(true)}
          red
        >
          {lang.profile.danger.changeCurriculum}
        </Button>
      </div>
    </Card >
  );
};

export default DangerCard;
