import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  sectionHeader: {
    marginBottom: '20px',
    fontWeight: 600,
  },
  buttonSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  checkmark: {
    color: theme.successColor,
  },
  microsoftEmail: {
    fontSize: '14px',
  },
}));
