import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  plansWrap: {
    display: 'flex',
    gap: '15px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  plansModal: {
    '& [class^="modalHeader"] > div': {
      width: '100%',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    gap: '1.5em',
  },
  paymentElement: {
    width: '50%',
  },
  paymentSummary: {
    padding: '1.5em',
    borderRadius: '12px',
    marginBottom: '0.5em',
    backgroundColor: `${theme.green}21`,
    color: theme.midnightGreen,
    width: '50%',
    height: 'fit-content',
  },
  summaryTitle: {
    fontWeight: 600,
    marginBottom: '0.5em',
  },
  subscriptionInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    opacity: '80%',
  },
  promoCodeInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    opacity: '80%',
    marginTop: '0.25em',
  },
  percentOff: {
    display: 'flex',
    alignItems: 'end',
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  line: {
    opacity: '40%',
  },
  discountText: {
    display: 'flex',
    justifyContent: 'end',
  },
  discountHighlightText: {
    color: theme.orangeSoda,
  },
  icon: {
    marginLeft: '0.5em',
    height: '1.25em',
    color: theme.grey,
  },
  removeCodeIcon: {
    cursor: 'pointer',
    height: '1.25em',
    color: theme.grey,
    '&:hover': {
      color: theme.orangeSoda,
    },
    marginLeft: '0.5em',
  },
  promoCodeWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '2em',
  },
  promoCodeInput: {
    width: '100%',
    marginRight: '0.5em',
    '& [class^="otl-inputText"]': {
      borderRadius: '12px',
      padding: '0.5em',
    },
  },
  promoButton: {
    padding: '1.85em 1.5em',
    borderRadius: '12px',
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    plansWrap: {
      flexDirection: 'column',
    },
    wrapper: {
      flexDirection: 'column-reverse',
    },
    paymentElement: {
      width: '100%',
    },
    paymentSummary: {
      width: 'auto',
    },
  },
}));
