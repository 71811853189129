import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useQuery from 'hooks/useQuery';
import routes from 'routes';

import Loading from 'components/common/Loading';

const MicrosoftLogoutRedirect = () => {
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const redirectUri = query().get('state');
    if (redirectUri) {
      window.location = redirectUri;
    } else {
      history.push(routes.home.ref());
    }
  }, [query, history]);

  return (
    <Loading
      active
      fullPage
    />
  );
};

export default MicrosoftLogoutRedirect;
