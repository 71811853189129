import React from 'react';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { EN, PT, selectUserLang } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import lang from 'lang';
import { addCookie } from 'utils';

import Tooltip from '../Tooltip';
import Button from 'components/common/Button';

import useStyles from './styles';

const LangSelector = ({ tipDirection }) => {
  const classes = useStyles();
  const locale = useSelector(selectUserLang);
  const toast = useToast();
  const loggedIn = useSelector(state => state.user.loggedIn);
  const [updateLangRequest, loading] = useApi(api.updateLang);

  const toggleLang = () => {
    addCookie('session_lang', locale === PT ? EN : PT);
    updateLangRequest([], { newLang: locale === PT ? EN : PT }, ({ data }) => {
      if (data.status !== 0 && loggedIn) {
        toast.error(lang.oops);
        return;
      }

      window.location.reload();
    });
  };

  return (
    <Tooltip
      tip={lang.changeLang}
      place={tipDirection}
    >
      <Button
        className={classes.langToggler}
        onClick={toggleLang}
        loading={loading}
      >
        <FontAwesomeIcon icon={faLanguage} />
        {locale.toUpperCase()}
      </Button>
    </Tooltip>
  );
};

LangSelector.propTypes = {
  tipDirection: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
};

export default LangSelector;
