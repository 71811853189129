import React from 'react';
import { InputText, InputArea } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { useSelector } from 'react-redux';

import useStyles from 'hooks/useStyles';

import Checkbox from '../Checkbox';
import Dropzone from '../Dropzone';
import Select from '../Select';

import styles from './styles';

const Input = ({ label, hint, errors, type, name, placeholder, value, defaultValue, rows, onDrop, multiple, options, minSize, maxSize, pattern, onChange, onFocus, onBlur, required, invalid, disabled, large, className, _ref, id }) => {
  const classes = useStyles(styles);
  const isDark = useSelector(state => state.page.isDark);

  const getInput = () => {
    if (type === 'date' || type === 'datetime') {
      return (
        <Datetime
          value={value}
          defaultValue={defaultValue}
          dateFormat={pattern || true}
          timeFormat={type === 'datetime'}
          strictParsing={false}
          onChange={onChange}
          className={classes.datepicker}
          inputProps={{
            name: name,
            placeholder: placeholder,
            onFocus: onFocus,
            onBlur: onBlur,
            required: required,
            disabled: disabled,
            ref: _ref,
            id: id,
            className: cx('otl-inputText', { dark: isDark, invalid, large }),
          }}
        />
      );
    }

    if (type === 'textarea') {
      return (
        <InputArea
          name={name}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          rows={rows}
          minLength={minSize}
          maxLength={maxSize}
          pattern={pattern}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
          invalid={invalid}
          disabled={disabled}
          large={large}
          dark={isDark}
          _ref={_ref}
          id={id}
        />
      );
    }

    if (type === 'file') {
      return (
        <Dropzone
          placeholder={placeholder}
          value={value}
          minSize={minSize}
          maxSize={maxSize}
          invalid={invalid}
          disabled={disabled}
          onDrop={onDrop}
          multiple={multiple}
        />
      );
    }

    if (type === 'select') {
      return (
        <Select
          name={name}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          multiple={multiple}
          options={options}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          invalid={invalid}
          disabled={disabled}
          ref={_ref}
          id={id}
        />
      );
    }

    return (
      <InputText
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        minLength={minSize}
        maxLength={maxSize}
        min={minSize}
        max={maxSize}
        pattern={pattern}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
        invalid={invalid}
        disabled={disabled}
        large={large}
        dark={isDark}
        _ref={_ref}
        id={id}
        className={classes.input}
      />
    );
  };

  const getErrors = () => (
    <Render when={errors && errors.length}>
      <div className={classes.errorsContainer}>
        {errors?.map(error => (
          <p key={error}>
            {error}
          </p>
        ))}
      </div>
    </Render>
  );

  if (type === 'checkbox') {
    return (
      <div className={className}>
        <Checkbox
          name={name}
          label={label}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
          invalid={invalid}
          disabled={disabled}
          dark={isDark}
          _ref={_ref}
          id={id}
        />
        {getErrors()}
      </div>
    );
  }

  return (
    <div className={className}>
      <Render when={label}>
        <div className={classes.headerItem}>
          <div className={classes.headerItem}>
            {label}
          </div>
          <Render when={hint}>
            <div className={cx(classes.headerItem, classes.hint)}>
              {hint}
            </div>
          </Render>
        </div>
      </Render>
      {getInput()}
      {getErrors()}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),

  type: PropTypes.oneOf(['text', 'number', 'password', 'email', 'date', 'datetime', 'select', 'textarea', 'file', 'checkbox']),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,

  rows: PropTypes.number,

  onDrop: PropTypes.func,
  multiple: PropTypes.bool,

  options: PropTypes.arrayOf(PropTypes.object),

  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  pattern: PropTypes.string,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,

  required: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,

  large: PropTypes.bool,

  className: PropTypes.string,
  _ref: PropTypes.object,
  id: PropTypes.string,
};

export default Input;
