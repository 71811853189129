import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import FilterPool from 'components/common/filter-selector/FilterPool';

import useStyles from './styles';

const YearsSelector = ({ newPreferences, setNewPreferences, years }) => {
  const classes = useStyles();

  const toggleSelectedYear = useCallback((selectedYear) => {
    const isActive = !!newPreferences.years.find(subject => subject.id === selectedYear.id);

    let newSelectedYears;
    if (isActive) {
      newSelectedYears = newPreferences.years.filter(subject => subject.id !== selectedYear.id);
    } else {
      newSelectedYears = [...newPreferences.years, selectedYear];
    }

    setNewPreferences({
      ...newPreferences,
      years: newSelectedYears,
    });
  }, [newPreferences, setNewPreferences]);

  const newYears = useMemo(() => years?.map(year => ({
    ...year,
    type: 'action',
    active: !!newPreferences.years.find(el => el.id === year.id),
    color: newPreferences.years.find(el => el.id === year.id) ? year.color : null,
    hoverColor: newPreferences.years.find(el => el.id === year.id) ? year.hoverColor : null,
    action: () => toggleSelectedYear(year),
  })), [newPreferences.years, years, toggleSelectedYear]);

  return (
    <div className={classes.areas}>
      <div className={classes.label}>
        {lang.profile.profilePreferences.years}
      </div>
      <FilterPool
        poolItems={newYears ? newYears : []}
        selectable={true}
      />
    </div>
  );
};

YearsSelector.propTypes = {
  newPreferences: PropTypes.object,
  setNewPreferences: PropTypes.func,
  years: PropTypes.array.isRequired,
};

export default YearsSelector;
