import { DELETE, GET, POST, PUT } from 'constants/requestMethods';

const api = {
  // General
  toggles: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/toggles', method: GET },

  // Me
  me: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me', method: GET },
  updateLang: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/update-lang', method: PUT },
  editInformation: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me', method: PUT },
  editPreferences: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/preferences/edit', method: POST },
  updateSpaceIdPreference: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/spaceid', method: PUT },
  addSpaceId: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/add-space', method: POST },
  upgradeUserPlan: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/plan', method: PUT },
  toggleCancelSubscription: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/subscription', method: PUT },
  getPaymentMethod: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/payment-method', method: GET },
  deletePaymentMethod: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/payment-method', method: DELETE },
  addPaymentMethod: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/payment-method', method: POST },
  replacePaymentMethod: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/me/payment-method/replace', method: POST },

  // Auth
  login: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/auth/login', method: POST },
  register: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/auth/register', method: POST },
  recoverEmail: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/auth/recover-email', method: POST },
  recoverPassword: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/auth/recover-password', method: PUT },
  logout: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/auth/logout', method: POST },
  remoteLogout: { apiUrl: process.env.REACT_APP_REMOTE_AUTH_API_URL, path: () => '/auth/logout', method: POST },
  updatePassword: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/auth/update-password', method: PUT },
  createPassword: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/auth/create-password', method: PUT },
  linkWithMicrosoft: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/auth/link/microsoft', method: POST },
  unlinkMicrosoftAccount: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/auth/link/microsoft', method: DELETE },
  checkUser: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/auth/users/check', method: POST },

  // Subjets
  getSubjects: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: (curriculumId) => `/subjects${curriculumId ? `?curriculumId=${curriculumId}` : ''}`, method: GET },

  // Tours
  startTourTest: { apiUrl: process.env.REACT_APP_TESTS_API_URL, path: () => '/tours', method: POST },

  // Years
  getYears: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: (curriculumId) => `/years${curriculumId ? `?curriculumId=${curriculumId}` : ''}`, method: GET },

  // Schools
  getSchoolFromToken: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: (token) => `/schools/token?token=${token}`, method: GET },

  // Curricula
  getCurricula: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/curricula', method: GET },

  //Plans
  getPlans: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: () => '/plans', method: GET },
  getPromoCode: { apiUrl: process.env.REACT_APP_AUTH_API_URL, path: (code) => `/plans/promotion-codes/${code}`, method: GET },
};

export default api;
