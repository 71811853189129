import React, { useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setPreferences } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import usePreferences from 'hooks/usePreferences';
import usePreferencesOptions from 'hooks/usePreferencesOptions';
import lang from 'lang';

import CurriculumSelector from '../CurriculumSelector';
import SubjectsSelector from '../SubjectsSelector';
import YearsSelector from '../YearsSelector';
import Modal from 'components/common/Modal';

const CONFIRMING = 'CONFIRMING';
const FORM = 'FORM';

const ChangeCurriculumModal = ({ open, close }) => {
  const [editPreferencesRequest] = useApi(api.editPreferences);
  const toast = useToast();
  const dispatch = useDispatch();

  const [newPreferences, setNewPreferences] = usePreferences();
  const [subjects, years, curricula] = usePreferencesOptions(newPreferences.curriculum?.id);
  const [state, setState] = useState(CONFIRMING);
  const [loading, setLoading] = useState(false);

  const clearAndClose = () => {
    setTimeout(() => {
      setState(CONFIRMING);
    }, 350);
    close();
  };

  const editPreferences = () => {
    const preferences = {
      years: newPreferences.years,
      subjects: newPreferences.subjects,
      curriculum: newPreferences.curriculum?.id,
    };

    setLoading(true);
    editPreferencesRequest([], preferences, ({ data }) => {
      setLoading(false);
      if (data.status === 0) {
        setTimeout(() => {
          toast.success(lang.changesSaved);
          dispatch(setPreferences(newPreferences));
          clearAndClose();
        }, 200);

      } else {
        setTimeout(() => {
          toast.error(lang.oops);
        }, 200);
      }
    });
  };

  const next = () => {
    switch (state) {
      case CONFIRMING:
        setState(FORM);
        break;
      case FORM:
        editPreferences();
        break;
      default:
        break;
    }
  };

  const actions = [
    {
      name: lang.cancel,
      color: 'black',
      onClick: clearAndClose,
    },
    {
      name: lang.confirm,
      color: state === CONFIRMING ? 'red' : 'blue',
      onClick: next,
      loading,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.profile.danger.changeCurriculum}
      actions={actions}
      center
      transition
      small
    >
      {state === CONFIRMING && (
        <div>
          {lang.profile.danger.changeCurriculumWarning}
        </div>
      )}
      {state === FORM && (
        <div>
          <CurriculumSelector
            newPreferences={newPreferences}
            setNewPreferences={setNewPreferences}
            curricula={curricula}
          />
          <Spacer px={20} />
          <SubjectsSelector
            newPreferences={newPreferences}
            setNewPreferences={setNewPreferences}
            subjects={subjects}
          />
          <Spacer px={20} />
          <YearsSelector
            newPreferences={newPreferences}
            setNewPreferences={setNewPreferences}
            years={years}
          />
        </div>
      )}
    </Modal>
  );
};

ChangeCurriculumModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default ChangeCurriculumModal;
