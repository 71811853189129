import React, { useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTheme } from 'theming';

import useStyles from './styles.js';

const FilterPoolItem = ({ poolItem, itemAction, selectable }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isHover, setIsHover] = useState(false);

  const onClick = () => {
    if (itemAction) {
      itemAction(poolItem);
    }
  };

  if (poolItem.type === 'label') {
    return (
      <div className={classes.labelItem}>
        {poolItem.name}
      </div>
    );
  }

  if (poolItem.type === 'action') {

    const inlineStyle = {
      transitionDuration: '100ms',
      backgroundColor: isHover
        ? (poolItem.hoverColor || theme.defaultLabelHoverColor)
        : (poolItem.color || theme.defaultLabelColor),
    };

    return (
      <div
        id={poolItem.id}
        className={cx(classes.item, classes.action)}
        style={inlineStyle}
        onClick={poolItem.action}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        {...poolItem.extraProps}
      >
        <div className={classes.itemName}>
          {poolItem.name}
        </div>
        <Render when={poolItem.icon}>
          <FontAwesomeIcon
            icon={poolItem.icon}
            className={classes.actionIcon}
          />
        </Render>
      </div>
    );
  }

  return (
    <div
      className={classes.item}
      style={{ backgroundColor: poolItem.color ? poolItem.color : theme.defaultLabelColor }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={classes.itemName}>
        {poolItem.name}
      </div>
      <Render when={!selectable}>
        <div
          className={classes.remove}
          onClick={onClick}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </Render>
    </div>
  );
};

FilterPoolItem.propTypes = {
  poolItem: PropTypes.object.isRequired,
  itemAction: PropTypes.func,
  selectable: PropTypes.bool,
};
export default FilterPoolItem;
