import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  homeLink: {
    color: theme.textColor,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  themeToggler: {
    cursor: 'pointer',
  },
  localeButton: {
    marginRight: '20px',
  },
  main: {
    backgroundColor: theme.backgroundColor,
    minHeight: '100vh',
    color: theme.textColor,
    '&.layoutWidth': {
      width: 'calc(100% - 237px)',
    },
  },
  sidebarSpacer: {
    paddingLeft: '237px',
  },
  layoutWrapper: {
    '& > .otl-wrapper > .otl-wrapperInnerPadding': {
      padding: '0',
    },
  },
  loading: {
    display: 'none',
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    main: {
      '&.layoutWidth': {
        width: '100%',
      },
    },
    sidebarSpacer: {
      padding: 'unset',
    },
    navbarSpacer: {
      paddingTop: '56px',
    },
  },
}));
