import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  pageContainer: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#104F55',
  },
  logoWrapper: {
    position: 'absolute',
    top: '25px',
  },
  cardWrapper: {
    width: '50%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    width: '60%',
    height: '60%',
    backgroundColor: '#E6EFE9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '18px',
    boxShadow: '5px 5px 25px #00000080',
    flexDirection: 'column',
    padding: '20px',
  },
  title: {
    fontSize: '35px',
    textAlign: 'center',
    paddingBottom: '30px',
    fontFamily: theme.logoFont,
    fontWeight: '600',
    color: '#7DD889',
  },
  message: {
    textAlign: 'center',
    paddingBottom: '30px',
    fontFamily: theme.logoFont,
    fontWeight: '600',
    color: '#104F55',
  },
}));
