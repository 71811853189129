import React from 'react';
import { Spinner } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles from 'hooks/useStyles';

import styles from './styles';

const Loading = ({ active, fullPage, className }) => {
  const classes = useStyles(styles);

  return (
    <Render when={active}>
      <div data-testid="spinner-wrapper" className={cx(classes.loadingContainer, { fullPage }, className)}>
        <Spinner
          className={cx(classes.loadingSpinner, { fullPage })}
        />
      </div>
    </Render>
  );
};

Loading.propTypes = {
  active: PropTypes.bool,
  fullPage: PropTypes.bool,
  className: PropTypes.string,
};

export default Loading;
