import { faFileAlt, faFilePdf, faFileImage, faFileExcel, faFileWord, faFilePowerpoint } from '@fortawesome/free-regular-svg-icons';
import Cookies from 'js-cookie';

import { LOCAL } from 'constants/environments';

/**
 * Constants
 */
const MAX_FILE_SIZE = 100000000;

/**
 * Checks if an array has duplicates.
 */
const hasDuplicates = (arr) => {
  return arr.some(x => arr.indexOf(x) !== arr.lastIndexOf(x));
};

/**
 * Gives textarea on field a perfect height
 */
const autoExpand = (field) => {
  field.style.height = 'inherit';
  const height = field.scrollHeight + 2;
  field.style.height = height + 'px';
};

/**
 * Retrieves the a file icon corresponding to the file extension.
 * @param {String} fileType The file extension
 */
const getFileIcon = (fileType) => {
  if (fileType === 'pdf') {
    return faFilePdf;
  }

  if (fileType === 'jpg' || fileType === 'png' || fileType === 'jpeg') {
    return faFileImage;
  }

  if (fileType === 'xlsx' || fileType === 'csv' || fileType === 'tsv' || fileType === 'ods') {
    return faFileExcel;
  }

  if (fileType === 'docx' || fileType === 'odt') {
    return faFileWord;
  }

  if (fileType === 'pptx' || fileType === 'odp') {
    return faFilePowerpoint;
  }

  return faFileAlt;
};

/**
 * Returns the coresponding HTTP error code based on the API error.
 * @param {Number} error The error returned by the API.
 */
const getErrorCode = (error) => {
  switch (error) {
    case -2:
      return '403';
    case 1:
      return '404';
    default:
      return '500';
  }
};

/**
 * Compares 2 dates and returns:
 *  0 if they are equal
 *  1 if a is after b
 *  -1 if a is before b
 */
const compareDates = (a, b) => {
  if (new Date(a).getTime() < new Date(b).getTime()) {
    return -1;
  }

  if (new Date(a).getTime() > new Date(b).getTime()) {
    return 1;
  }

  return 0;
};

/**
 * Compares 2 values:
 *  0 if they are equal
 *  1 if a is larger than b
 *  -1 if a is smaller than b
 */
const simpleCompare = (a, b) => {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};

const sortByActiveAndName = (preferences) => {
  return preferences.sort((a, b) => {
    if (a.active && !b.active) {
      return -1;
    }

    if (b.active && !a.active) {
      return 1;
    }

    return 0;
  });
};

const getCookie = (key) => {
  return Cookies.get(key);
};

const addCookie = (key, value, expires) => {
  const expiresDefault = new Date();
  expiresDefault.setMonth(expiresDefault.getMonth() + 1);

  let domain = process.env.REACT_APP_INTUITIVO_DOMAIN;
  if (process.env.REACT_APP_NODE_ENV === LOCAL) {
    const hostname = window.location.hostname;
    domain = hostname;
  }

  Cookies.set(key, value, { domain: domain, expires: expires || expiresDefault });
};

const removeCookie = (key) => {
  let domain = process.env.REACT_APP_INTUITIVO_DOMAIN;
  if (process.env.REACT_APP_NODE_ENV === LOCAL) {
    const hostname = window.location.hostname;
    domain = hostname;
  }

  Cookies.remove(key, { domain: domain });
};

export {
  MAX_FILE_SIZE,
  hasDuplicates,
  autoExpand,
  getFileIcon,
  getErrorCode,
  compareDates,
  simpleCompare,
  sortByActiveAndName,
  addCookie,
  getCookie,
  removeCookie,
};
