import React, { Fragment, useState } from 'react';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import { useSelector } from 'react-redux';

import { selectUserData } from 'actions/userActions';
import { linkRequest } from 'authConfig';
import lang from 'lang';

import UnlinkMicrosoftAccountModal from '../UnlinkMicrosoftAccountModal';
import Button from 'components/common/Button';
import MicrosoftButton from 'components/common/MicrosoftButton';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const LinkWithMicrosoft = () => {
  const classes = useStyles();
  const { hasMicrosoftStrategy, microsoftEmail, hasLocalStrategy } = useSelector(selectUserData);
  const [unlinkMicrosoftAccountModal, setUnlinkMicrosoftAccountModal] = useState(false);

  return (
    <Fragment>
      <UnlinkMicrosoftAccountModal
        open={unlinkMicrosoftAccountModal}
        close={() => setUnlinkMicrosoftAccountModal(false)}
      />
      <div className={classes.sectionHeader}>
        {lang.profile.security.linkWithMicrosoft}
      </div>
      <div className={classes.buttonSection}>
        <MicrosoftButton
          label={lang.profile.security.linkWithMicrosoft}
          request={linkRequest}
          disabled={hasMicrosoftStrategy}
        />
        <Render when={hasMicrosoftStrategy}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={classes.checkmark}
          />
          <div className={classes.microsoftEmail}>
            {microsoftEmail}
          </div>
          <Tooltip tip={hasLocalStrategy ? null : lang.profile.security.unlinkMicrosoftAccountDisabled}>
            <Button
              onClick={() => setUnlinkMicrosoftAccountModal(true)}
              red
              sibling
              disabled={!hasLocalStrategy}
            >
              {lang.profile.security.unlinkMicrosoftAccount}
            </Button>
          </Tooltip>
        </Render>
      </div>
    </Fragment>
  );
};

export default LinkWithMicrosoft;
