export default {
  explore: 'explore',
  explorePage: 'explore-page',
  azureOauth: 'azure-oauth',
  iave: 'iave',
  plans: 'plans',
  fillTheGaps: 'fill_the_gaps',
  ordering: 'ordering',
  caption: 'caption',
  sections: 'sections',
  quillAnswers: 'quill-answers',
  multipleCorrectChoices: 'multiple-correct-choices',
  horizontalChoices: 'horizontal-choices',
  shortTextAnswer: 'short-text-answer',
  ftgExtra: 'ftg-extra',
  captionExtra: 'caption-extra',
  ftgDropdown: 'ftg-dropdown',
  captionDropdown: 'caption-dropdown',
  ftgWrite: 'ftg-write',
  guestAttempts: 'guest-attempts',
  navigation: 'navigation',
  sectionTimer: 'section-timer',
  pauseItem: 'pause-item',
  textAfterItem: 'text-after-item',
  orderDropdown: 'order-dropdown',
  exportIdentifiers: 'export-identifiers',
  orderChoicesToggle: 'order-choices',
  exportAttemptsCsv: 'export-attempts-csv',
  captionWrite: 'caption-write',
  connecting: 'connecting',
  segmentation: 'segmentation',
  videoUpload: 'video-upload',
  soundControl: 'sound-control',
  videoControl: 'video-control',
  maintenance: 'maintenance',
  adminGroups: 'admin-groups',
  paymentMethod: 'payment-method',
  register: 'register',
  teacherOnboarding: 'teacher-onboarding',
  exploreCurriculum: 'explore-curriculum',
};
