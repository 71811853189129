import { createUseStyles } from 'react-jss';

export default createUseStyles({
  input: {
    marginTop: '20px',
    width: '100%',
  },
  button: {
    marginTop: '20px',
    marginBottom: '5px',
    border: 'none',
  },
});
