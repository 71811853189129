import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  card: {
    backgroundColor: theme.backgroundColor,
    width: '100% !important',
    height: 'fit-content',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 20px 20px 20px',
  },
  label: {
    marginBottom: '0.5em',
    fontWeight: 600,
  },
  labelDescription: {
    fontSize: '14px',
    marginBottom: '10px',
    color: theme.grey,
  },
  preferencesToggle: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    card: {
      width: '100% !important',
    },
  },
}));
