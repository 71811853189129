import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import useFeature from 'hooks/useFeature';
import routes from 'routes';
import toggles from 'toggles';

import PrivateRoute from 'components/common/PrivateRoute';
import Limbo from 'pages/Limbo';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import Maintenance from 'pages/Maintenance';
import MicrosoftLinkRedirect from 'pages/MicrosoftLinkRedirect';
import MicrosoftLoginRedirect from 'pages/MicrosoftLoginRedirect';
import MicrosoftLogoutRedirect from 'pages/MicrosoftLogoutRedirect';
import MicrosoftRegisterRedirect from 'pages/MicrosoftRegisterRedirect';
import NotFound from 'pages/NotFound';
import Onboarding from 'pages/Onboarding';
import Profile from 'pages/Profile';
import Recover from 'pages/Recover';
import Register from 'pages/Register';
import SchoolInvite from 'pages/SchoolInvite';

const Router = () => {
  const appLoading = useSelector(state => state.page.appLoading);
  const iaveToggle = useFeature(toggles.iave);
  const maintenanceToggle = useFeature(toggles.maintenance);
  const registerToggle = useFeature(toggles.register);

  if (appLoading) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={routes.home.def}>
        <Login />
      </Route>
      {!iaveToggle && registerToggle &&
        <Route exact path={routes.register.def}>
          <Register />
        </Route>
      }
      <Route exact path={routes.schoolInvite.def}>
        <SchoolInvite />
      </Route>
      <Route exact path={routes.registerMicrosoft.def}>
        <MicrosoftRegisterRedirect />
      </Route>
      <Route exact path={routes.loginMicrosoft.def}>
        <MicrosoftLoginRedirect />
      </Route>
      <Route exact path={routes.linkMicrosoft.def}>
        <MicrosoftLinkRedirect />
      </Route>
      <Route exact path={routes.recover.def}>
        <Recover />
      </Route>
      <PrivateRoute exact path={routes.profile.def}>
        <Profile />
      </PrivateRoute>
      <PrivateRoute exact path={routes.limbo.def}>
        <Limbo />
      </PrivateRoute>
      <PrivateRoute exact path={routes.onboarding.def}>
        <Onboarding />
      </PrivateRoute>
      <Route exact path={routes.logout.def}>
        <Logout />
      </Route>
      <Route exact path={routes.logoutMicrosoft.def}>
        <MicrosoftLogoutRedirect />
      </Route>
      {maintenanceToggle &&
        <Route exact path={routes.maintenance.def}>
          <Maintenance />
        </Route>
      }
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Router;
