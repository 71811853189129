import React, { Fragment } from 'react';
import { Spacer } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';

import { enableLocalStrategy, selectUserData } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import useInput from 'hooks/useInput';
import lang from 'lang';

import Button from 'components/common/Button';
import Input from 'components/common/input/Input';

import useStyles from './styles';

const TIMEOUT = 200;

const ChangePassword = () => {
  const classes = useStyles();
  const toast = useToast();
  const [updatePasswordRequest, loading] = useApi(api.updatePassword);
  const [createPasswordRequest] = useApi(api.createPassword);
  const { hasLocalStrategy } = useSelector(selectUserData);
  const dispatch = useDispatch();

  const [password, setPassword, passwordErrors, setPasswordErrors] = useInput('');
  const [confirmPassword, setConfirmPassword, confirmPasswordErrors, setConfirmPasswordErrors] = useInput('');

  const updatePassword = () => {
    let valid = true;
    const localPasswordErrors = [];
    const localRepeatPasswordErrors = [];

    if (password.length < 8) {
      localPasswordErrors.push(lang.register.passwordLengthError);
      valid = false;
    }

    if (!/[A-Z]/.test(password)) {
      localPasswordErrors.push(lang.register.passwordUpperCharsError);
      valid = false;
    }

    if (!/[0-9]/.test(password)) {
      localPasswordErrors.push(lang.register.passwordNumberError);
      valid = false;
    }

    if (!/[^\w\s]/.test(password)) {
      localPasswordErrors.push(lang.register.passwordSpecialCharsError);
      valid = false;
    }

    if (password !== confirmPassword) {
      localRepeatPasswordErrors.push(lang.register.passwordsDifferentError);
      valid = false;
    }

    if (!valid) {
      setTimeout(() => {
        setPasswordErrors(localPasswordErrors);
        setConfirmPasswordErrors(localRepeatPasswordErrors);
      }, TIMEOUT);
      return;
    }

    setPasswordErrors([]);
    setConfirmPasswordErrors([]);

    if (!hasLocalStrategy) {
      createPasswordRequest([], { newPassword: password, newConfirmPassword: confirmPassword }, ({ data }) => {
        if (data.status === 0) {
          setTimeout(() => {
            dispatch(enableLocalStrategy());
            toast.success(lang.recover.passwordUpdated);
          }, TIMEOUT);
          return;
        }

        setTimeout(() => {
          toast.error(lang.oops);
        }, TIMEOUT);
      });
    }

    updatePasswordRequest([], { newPassword: password, newConfirmPassword: confirmPassword }, ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          toast.success(lang.recover.passwordUpdated);
        }, TIMEOUT);
        return;
      }

      setTimeout(() => {
        toast.error(lang.oops);
      }, TIMEOUT);
    });
  };

  return (
    <Fragment>
      <div className={classes.sectionHeader}>
        {hasLocalStrategy ? lang.profile.security.changePassword : lang.profile.security.createPassword}
      </div>
      <Input
        type={'password'}
        label={lang.profile.newPassword}
        placeholder={lang.profile.newPasswordPlaceholder}
        hint={lang.profile.passwordHint}
        onChange={(event) => setPassword(event.target.value)}
        errors={passwordErrors}
        invalid={passwordErrors.length !== 0}
        required
      />
      <Spacer px={15} />
      <Input
        type={'password'}
        label={lang.profile.confirmPassword}
        placeholder={lang.profile.confirmPasswordPlaceholder}
        onChange={(event) => setConfirmPassword(event.target.value)}
        errors={confirmPasswordErrors}
        invalid={confirmPasswordErrors.length !== 0}
        required
      />
      <Spacer px={20} />
      <div>
        <Button
          onClick={updatePassword}
          loading={loading}
        >
          {lang.saveChanges}
        </Button>
      </div>
    </Fragment>
  );
};

export default ChangePassword;
