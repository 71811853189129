import React, { useCallback, useEffect } from 'react';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import mixpanel from 'mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { bootUser, selectUserIsLoggedIn, selectUserLang } from 'actions/userActions';
import api from 'api';
import { registerRequest as registerAuthRequest } from 'authConfig';
import { LOGIN_STRATEGY, SESSION_NONCE } from 'constants/cookies';
import { LOCAL_STRATEGY } from 'constants/loginStrategies';
import useApi from 'hooks/useApi';
import useFeature from 'hooks/useFeature';
import useLoading from 'hooks/useLoading';
import usePageLogic from 'hooks/usePageLogic';
import useQuery from 'hooks/useQuery';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { addCookie } from 'utils';

import LangSelector from 'components/common/LangSelector';
import Logo from 'components/common/Logo';
import MicrosoftButton from 'components/common/MicrosoftButton';
import RegisterForm from 'components/register/RegisterForm';

import useStyles from './styles';

const Register = () => {
  const classes = useStyles();
  const { loaded } = usePageLogic(lang.register.header, false);
  const toast = useToast();
  const [registerRequest] = useApi(api.register, true);
  const azureOauthToggle = useFeature(toggles.azureOauth);
  const query = useQuery();
  const locale = useSelector(selectUserLang);
  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const history = useHistory();
  const exploreToggle = useFeature(toggles.explore);
  const dispatch = useDispatch();

  const [loading, setLoading] = useLoading(false);

  useEffect(() => {
    loaded();
    if (isLoggedIn) {
      window.location = routes.profile.ref(query().get('plan'), query().get('type'));
    }
  }, [loaded, isLoggedIn, query]);

  const handleRegister = useCallback((newUser) => {
    const groupIdToken = query().get('group');
    const plan = query().get('plan');
    const subscriptionType = query().get('type');

    const payload = {
      ...newUser,
      locale: locale,
      loginStrategy: LOCAL_STRATEGY,
    };

    if (groupIdToken) {
      payload.groupIdToken = groupIdToken;
    }

    setLoading(true);
    registerRequest([], payload, ({ data }) => {
      if (data.status === 0) {
        addCookie(SESSION_NONCE, data.sessionToken);
        addCookie(LOGIN_STRATEGY, LOCAL_STRATEGY);
        dispatch(bootUser(data.user));
        mixpanel.identify(data.user.id);

        if (plan) {
          window.location = routes.profile.ref(plan, subscriptionType);
        } else if (exploreToggle) {
          history.push(routes.onboarding.ref());
        } else {
          window.location = routes.tests.ref();
        }
        return;
      }

      setLoading(false);

      if (data.status === 2) {
        toast.warning(lang.register.emailExistsError);
        return;
      }

      toast.error(lang.oops);
    });
  }, [dispatch, exploreToggle, history, locale, query, registerRequest, setLoading, toast]);

  return (
    <div className={classes.pageContainer}>
      <div className={classes.langSelectorContainer}>
        <LangSelector tipDirection="left" />
      </div>
      <div className={classes.logoContainer}>
        <div className={classes.logo}>
          <Logo large dark />
        </div>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.registerContainer}>
          <div className={classes.registerHeader}>
            <div className={classes.registerTitle}>
              {lang.register.header}
            </div>
            <div className={classes.registerSubtitle}>
              {lang.register.description}
            </div>
          </div>
          <Render when={azureOauthToggle}>
            <MicrosoftButton
              label={lang.register.registerWithMicrosoft}
              request={registerAuthRequest}
            />
            <div className={classes.loginStrategiesSeparator}>
              <div className={classes.separatorLine} />
              {lang.or.toUpperCase()}
              <div className={classes.separatorLine} />
            </div>
          </Render>
          <RegisterForm
            handleRegister={handleRegister}
            loading={loading}
          />
          <div className={classes.separator} />
          <div className={classes.loginContainer}>
            {lang.register.alreadyHaveAccount}
            <Link
              to={routes.home.ref() + (query().get('plan') ? `?plan=${query().get('plan')}${query().get('type') ? '&type=' + query().get('type') : ''}` : '')}
              className={classes.loginLink}
            >
              {lang.register.login}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
