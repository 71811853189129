import React, { useEffect, useState } from 'react';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { selectUserLang } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import useInput from 'hooks/useInput';
import usePageLogic from 'hooks/usePageLogic';
import useQuery from 'hooks/useQuery';
import lang from 'lang';
import routes from 'routes';

import Button from 'components/common/Button';
import Input from 'components/common/input/Input';
import LangSelector from 'components/common/LangSelector';
import Logo from 'components/common/Logo';

import useStyles from './styles';

const TIMEOUT = 200;

const Recover = () => {
  const classes = useStyles();
  const { loaded } = usePageLogic('Recover', false);
  const toast = useToast();
  const locale = useSelector(selectUserLang);
  const query = useQuery();
  const history = useHistory();
  const [recoverEmailRequest] = useApi(api.recoverEmail, true);
  const [recoverPasswordRequest] = useApi(api.recoverPassword, true);

  const [loading, setLoading] = useState(false);
  const [email, setEmail, emailErrors, setEmailErrors] = useInput('');
  const [password, setPassword, passwordErrors, setPasswordErrors] = useInput('');
  const [passwordRepeat, setPasswordRepeat, passwordRepeatErrors, setPasswordRepeatErrors] = useInput('');

  useEffect(() => {
    loaded();
  }, [loaded]);

  const handleRecover = (event) => {
    event.preventDefault();
    const token = query().get('token');
    setPasswordErrors([]);
    setPasswordRepeatErrors([]);
    setEmailErrors([]);

    if (!token) {
      setLoading(true);
      recoverEmailRequest([], { email, locale }, ({ data }) => {
        setLoading(false);
        if (data.status === 0) {
          toast.success(lang.recover.emailSent);
        } else if (data.status === 3) {
          setEmailErrors([lang.recover.emailError]);
        } else {
          toast.error(lang.oops);
        }
      });
    } else {
      let valid = true;
      const localPasswordErrors = [];
      const localRepeatPasswordErrors = [];

      if (password.length < 8) {
        localPasswordErrors.push(lang.recover.passwordLengthError);
        valid = false;
      }

      if (!/[A-Z]/.test(password)) {
        localPasswordErrors.push(lang.recover.passwordUpperCharsError);
        valid = false;
      }

      if (!/[0-9]/.test(password)) {
        localPasswordErrors.push(lang.recover.passwordNumberError);
        valid = false;
      }

      if (!/[^\w\s]/.test(password)) {
        localPasswordErrors.push(lang.recover.passwordSpecialCharsError);
        valid = false;
      }

      if (password !== passwordRepeat) {
        localRepeatPasswordErrors.push(lang.recover.passwordsDifferentError);
        valid = false;
      }

      if (!valid) {
        setTimeout(() => {
          setPasswordErrors(localPasswordErrors);
          setPasswordRepeatErrors(localRepeatPasswordErrors);
        }, TIMEOUT);
        return;
      }

      setPasswordErrors([]);
      setPasswordRepeatErrors([]);
      setLoading(true);
      recoverPasswordRequest([], { token, password, passwordRepeat }, ({ data }) => {
        setLoading(false);
        if (data.status === 0) {
          setTimeout(() => {
            toast.success(lang.recover.passwordUpdated);
            history.push(routes.home.ref());
          }, TIMEOUT);
        } else {
          toast.error(lang.oops);
        }
      });
    }
  };

  const token = query().get('token');

  return (
    <div className={classes.pageContainer}>
      <div className={classes.langSelectorContainer}>
        <LangSelector tipDirection="left" />
      </div>
      <div className={classes.logoContainer}>
        <div className={classes.logo}>
          <Logo large dark />
        </div>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.recoverContainer}>
          <div className={classes.recoverHeader}>
            <div className={classes.recoverTitle}>
              {lang.recover.header}
            </div>
            <div className={classes.recoverSubtitle}>
              {token ? lang.recover.descriptionPassword : lang.recover.description}
            </div>
          </div>
          <form onSubmit={handleRecover}>
            <Render when={!token}>
              <Input
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                errors={emailErrors}
                invalid={emailErrors.length !== 0}
                label={lang.recover.emailLabel}
                placeholder={lang.recover.emailPlaceholder}
                className={classes.input}
              />
            </Render>
            <Render when={token}>
              <Input
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                errors={passwordErrors}
                invalid={passwordErrors.length !== 0}
                label={lang.recover.passwordLabel}
                placeholder={lang.recover.passwordPlaceholder}
                className={classes.input}
                hint={lang.profile.passwordHint}
              />
              <Input
                type="password"
                value={passwordRepeat}
                onChange={(event) => setPasswordRepeat(event.target.value)}
                errors={passwordRepeatErrors}
                invalid={passwordRepeatErrors.length !== 0}
                label={lang.recover.passwordRepeatLabel}
                placeholder={lang.recover.passwordRepeatPlaceholder}
                className={classes.input}
              />
            </Render>
            <Button
              type="submit"
              className={classes.button}
              stretch
              loading={loading}
            >
              {lang.send}
            </Button>
          </form>
          <div className={classes.separator} />
          <div className={classes.loginContainer}>
            <Link
              to={routes.home.ref()}
              className={classes.loginLink}
            >
              {lang.login.header}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recover;
