import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import Button from 'components/common/Button';

import useStyles from './styles';

const MicrosoftButton = ({ label, request, disabled }) => {
  const classes = useStyles();
  const { instance } = useMsal();
  const toast = useToast();

  const handleRegister = () => {
    instance.loginRedirect(request).catch(() => {
      toast.error(lang.oops);
    });
  };

  return (
    <Button
      sibling
      onClick={handleRegister}
      className={classes.microsoftButton}
      disabled={disabled}
    >
      <img
        src={`${process.env.REACT_APP_S3_URL}/resources/microsoft-logo.svg`}
        alt=""
      />
      {label}
    </Button>
  );
};

MicrosoftButton.propTypes = {
  label: PropTypes.string,
  request: PropTypes.object,
  disabled: PropTypes.bool,
};

export default MicrosoftButton;
