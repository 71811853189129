import { BOOT_USER, LANG_SET, SET_PREFERENCES, PT, SET_INFORMATION, SET_FEATURE_TOGGLES, UNLINK_MICROSOFT_ACCOUNT, ENABLE_LOCAL_STRATEGY, SET_PLAN, SET_PERSONAL_SPACE, SET_PAYMENT_METHOD, LINK_MICROSOFT_ACCOUNT, SET_PLAN_ACTIVE } from 'actions/userActions';
import lang from 'lang';
import { getCookie } from 'utils';

const defaultLang = getCookie('session_lang') || PT;
lang.setLanguage(defaultLang);

const initialState = {
  loggedIn: false,
  data: {},
  lang: defaultLang,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOT_USER:
      return {
        ...state,
        loggedIn: true,
        data: action.data,
      };
    case LANG_SET:
      return {
        ...state,
        lang: action.lang,
      };
    case SET_PREFERENCES:
      return {
        ...state,
        data: {
          ...state.data,
          subjects: action.subjects,
          years: action.years,
          country: action.country,
          curriculum: action.curriculum,
        },
      };
    case SET_INFORMATION:
      return {
        ...state,
        data: {
          ...state.data,
          fullName: action.fullName,
          email: action.email,
        },
      };
    case SET_FEATURE_TOGGLES:
      return {
        ...state,
        data: {
          ...state.data,
          featureToggles: action.featureToggles,
        },
      };
    case UNLINK_MICROSOFT_ACCOUNT:
      return {
        ...state,
        data: {
          ...state.data,
          hasMicrosoftStrategy: false,
          microsoftEmail: null,
        },
      };
    case LINK_MICROSOFT_ACCOUNT:
      return {
        ...state,
        data: {
          ...state.data,
          hasMicrosoftStrategy: true,
          microsoftEmail: action.microsoftEmail,
        },
      };
    case ENABLE_LOCAL_STRATEGY:
      return {
        ...state,
        data: {
          ...state.data,
          hasLocalStrategy: true,
        },
      };
    case SET_PLAN:
      return {
        ...state,
        data: {
          ...state.data,
          plan: action.plan,
        },
      };
    case SET_PLAN_ACTIVE:
      return {
        ...state,
        data: {
          ...state.data,
          plan: {
            ...state.data.plan,
            active: action.active,
          },
        },
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        data: {
          ...state.data,
          paymentMethod: action.paymentMethod,
        },
      };
    case SET_PERSONAL_SPACE:
      return {
        ...state,
        data: {
          ...state.data,
          hasPersonalSpace: action.hasPersonalSpace,
        },
      };
    default:
      return state;
  }
};

export default reducer;
