import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  button: {
    backgroundColor: theme.buttonBackground2,
    border: 'none',
    borderRadius: '40px',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: theme.buttonBackgroundHover2,
    },
    '&:focus': {
      backgroundColor: theme.buttonBackgroundHover2,
    },
    '&.black': {
      backgroundColor: theme.buttonBackgroundBlack,
      '&:hover': {
        backgroundColor: theme.buttonBackgroundHoverBlack,
      },
      '&:focus': {
        backgroundColor: theme.buttonBackgroundHoverBlack,
      },
    },
    '&.red': {
      backgroundColor: theme.buttonBackgroundRed,
      '&:hover': {
        backgroundColor: theme.buttonBackgroundHoverRed,
      },
      '&:focus': {
        backgroundColor: theme.buttonBackgroundHoverRed,
      },
    },
    '&.gray': {
      color: theme.textColor,
      backgroundColor: theme.buttonBackgroundGrey,
      '&:hover': {
        backgroundColor: theme.buttonBackgroundHoverGrey,
      },
      '&:focus': {
        backgroundColor: theme.buttonBackgroundHoverGrey,
      },
    },
    '&.sibling': {
      borderRadius: '10px',
      marginLeft: '10px',
      '&:first-of-type': {
        marginLeft: '0',
      },
    },
  },
}));
