import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  item: {
    minHeight: '1.69em',
    minWidth: '2em',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.textColorContrast,
    fontSize: '12px',
    marginRight: '0.5em',
  },
  itemName: {
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    paddingRight: '5px',
  },
  remove: {
    alignItems: 'center',
    display: 'flex',
    right: '0',
    height: '100%',
    paddingRight: '5px',
    borderRadius: '4px',
    cursor: 'pointer',
    opacity: '0.6',
    '&:hover': {
      opacity: '1',
    },
  },
  labelItem: {
    marginRight: '0.5em',
  },
  action: {
    cursor: 'pointer',
    position: 'relative',
  },
  actionIcon: {
    paddingRight: '5px',
  },
}));
