import { ADMIN, STUDENT, SUPERADMIN, TEACHER } from 'constants/roles';

const PT = 'pt';
const EN = 'en';

/**
 * Action constants
 */
const BOOT_USER = 'BOOT_USER';
const LANG_SET = 'LANG_SET';
const SET_PREFERENCES = 'SET_PREFERENCES';
const SET_INFORMATION = 'SET_INFORMATION';
const SET_FEATURE_TOGGLES = 'SET_FEATURE_TOGGLES';
const UNLINK_MICROSOFT_ACCOUNT = 'UNLINK_MICROSOFT_ACCOUNT';
const LINK_MICROSOFT_ACCOUNT = 'LINK_MICROSOFT_ACCOUNT';
const ENABLE_LOCAL_STRATEGY = 'ENABLE_LOCAL_STRATEGY';
const SET_PLAN = 'SET_PLAN';
const SET_PLAN_ACTIVE = 'SET_PLAN_ACTIVE';
const SET_PERSONAL_SPACE = 'SET_PERSONAL_SPACE';
const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';

/**
 * Action creators
 */
const bootUser = (data) => ({
  type: BOOT_USER,
  data: data,
});

const langSet = (newLang) => ({
  type: LANG_SET,
  lang: newLang,
});

const setPreferences = (preferences) => {
  return {
    type: SET_PREFERENCES,
    subjects: preferences.subjects,
    years: preferences.years,
    country: preferences.country,
    curriculum: preferences.curriculum,
  };
};

const setInformation = ({ fullName, email }) => {
  return {
    type: SET_INFORMATION,
    fullName: fullName,
    email: email,
  };
};

const setPlan = (plan) => {
  return {
    type: SET_PLAN,
    plan: plan,
  };
};

const setPlanActive = (active) => {
  return {
    type: SET_PLAN_ACTIVE,
    active: active,
  };
};

const setPaymentMethod = (paymentMethod) => {
  return {
    type: SET_PAYMENT_METHOD,
    paymentMethod: paymentMethod,
  };
};

const setFeatureToggles = (featureToggles) => {
  return {
    type: SET_FEATURE_TOGGLES,
    featureToggles: featureToggles,
  };
};

const unlinkMicrosoftAccount = () => {
  return {
    type: UNLINK_MICROSOFT_ACCOUNT,
  };
};
const linkMicrosoftAccount = (microsoftEmail) => {
  return {
    type: LINK_MICROSOFT_ACCOUNT,
    microsoftEmail: microsoftEmail,
  };
};

const enableLocalStrategy = () => {
  return {
    type: ENABLE_LOCAL_STRATEGY,
  };
};

const setPersonalSpace = (hasPersonalSpace) => {
  return {
    type: SET_PERSONAL_SPACE,
    hasPersonalSpace: hasPersonalSpace,
  };
};

/**
 * Selectors
 */
const selectUserSubjects = (state) => {
  return state.user.data.subjects;
};

const selectUserYears = (state) => {
  return state.user.data.years;
};

const selectUserCountry = (state) => {
  return state.user.data.country;
};

const selectUserFeatureToggles = (state) => {
  return state.user.data.featureToggles;
};

const selectUserLang = (state) => {
  return state.user.lang;
};

const selectUserData = (state) => {
  return state.user.data;
};

const selectUserIsLoggedIn = (state) => {
  return !!state.user.data.id;
};

const selectUserIsTeacher = (state) => {
  return state.user.data.role === TEACHER;
};

const selectIsAdmin = (state) => {
  return state.user.data.role === ADMIN;
};

const selectUserPlan = (state) => {
  return state.user.data.plan;
};

const selectPaymentMethod = (state) => {
  return state.user.data.paymentMethod;
};

const selectHasPersonalSpace = (state) => {
  return state.user.data.hasPersonalSpace;
};

const selectUserSchools = (state) => {
  return state.user.data.schools;
};

const selectIsSuperadmin = (state) => {
  return state.user.data.role === SUPERADMIN;
};

const selectUserIsStudent = (state) => {
  return state.user.data.role === STUDENT;
};

const selectUserSpaceIdPreference = (state) => {
  return state.user.data.spaceIdPreference;
};

const selectUserCurriculum = (state) => {
  return state.user.data.curriculum;
};

export {
  PT,
  EN,
  BOOT_USER,
  LANG_SET,
  SET_PREFERENCES,
  SET_INFORMATION,
  SET_FEATURE_TOGGLES,
  UNLINK_MICROSOFT_ACCOUNT,
  LINK_MICROSOFT_ACCOUNT,
  ENABLE_LOCAL_STRATEGY,
  SET_PLAN,
  SET_PERSONAL_SPACE,
  SET_PAYMENT_METHOD,
  SET_PLAN_ACTIVE,
  langSet,
  bootUser,
  setPreferences,
  setInformation,
  setPlan,
  setPaymentMethod,
  setFeatureToggles,
  unlinkMicrosoftAccount,
  linkMicrosoftAccount,
  enableLocalStrategy,
  setPersonalSpace,
  selectUserSubjects,
  selectUserYears,
  selectUserFeatureToggles,
  selectUserLang,
  selectUserData,
  selectUserIsLoggedIn,
  selectUserIsTeacher,
  selectIsAdmin,
  selectUserPlan,
  selectPaymentMethod,
  selectHasPersonalSpace,
  selectUserSchools,
  selectIsSuperadmin,
  selectUserIsStudent,
  setPlanActive,
  selectUserSpaceIdPreference,
  selectUserCountry,
  selectUserCurriculum,
};
