import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import lang from 'lang';

import { ReactComponent as MaintenanceAvatar } from '../../assets/images/robot.svg';
import Logo from 'components/common/Logo';

import useStyles from './styles';

const Maintenance = () => {
  const classes = useStyles();
  const [themeNumber, setThemeNumber] = useState(null);

  useEffect(() => {
    const themes = ['1', '2', '3'];
    if (!themeNumber) {
      setThemeNumber(themes[Math.floor(Math.random() * themes.length)]);
    }
  }, [themeNumber]);

  return (
    <div className={cx(classes.pageContainer, { theme1: themeNumber === '1', theme2: themeNumber === '2', theme3: themeNumber === '3' })}>
      <div className={classes.logoWrapper}>
        <Logo dark medium />
      </div>
      {themeNumber === '3' ?
        <>
          <div className={cx(classes.leftWrapper, { theme3: true })}>
            <div className={classes.title}>
              {lang.maintenance.title}
            </div>
            <div className={cx(classes.message, { theme3: true })}>
              {lang.maintenance.quote}
            </div>
          </div>
          <div className={classes.rightWrapper}>
            <div className={cx(classes.card, { theme3: true })}>
              <MaintenanceAvatar />
            </div>
          </div>
        </>
        :
        <>
          {
            themeNumber === '2' ?
              <>
                <div className={classes.leftWrapper}>
                  <div className={cx(classes.card, { theme1: themeNumber === '1', theme2: themeNumber === '2', theme3: themeNumber === '3' })}>
                    <div className={classes.title}>
                      {lang.maintenance.title}
                    </div>
                    <div className={cx(classes.message, { theme1: themeNumber === '1', theme2: themeNumber === '2', theme3: themeNumber === '3' })}>
                      {lang.maintenance.quote}
                    </div>
                  </div>
                </div>
                <div className={classes.rightWrapper}>
                  <div className={cx(classes.avatar, { theme1: themeNumber === '1', theme2: themeNumber === '2', theme3: themeNumber === '3' })}>
                    <MaintenanceAvatar />
                  </div>
                </div>
              </>
              :
              <div className={cx(classes.pageContainer, { theme1: true })}>
                <div className={classes.logoWrapper}>
                  <Logo medium />
                </div>
                <div className={cx(classes.message, { theme1: true })}>
                  {lang.maintenance.quote}
                </div>
                <div className={classes.avatar}>
                  <MaintenanceAvatar />
                </div>
              </div>
          }
        </>
      }
    </div>
  );
};

export default Maintenance;
