import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUserSubjects, selectUserYears, selectUserCountry, selectUserCurriculum } from 'actions/userActions';

const usePreferences = () => {
  const userYears = useSelector(selectUserYears);
  const userSubjects = useSelector(selectUserSubjects);
  const userCountry = useSelector(selectUserCountry);
  const userCurriculum = useSelector(selectUserCurriculum);

  const [selectedPreferences, setSelectedPreferences] = useState([]);

  useEffect(() => {
    const subjects = userSubjects.map(subject => ({
      ...subject,
      color: '#CB6EB7',
      hoverColor: '#B4419B',
      type: 'subject',
      active: true,
    }));

    const years = userYears.map(year => ({
      ...year,
      color: '#F79649',
      hoverColor: '#EB6F0A',
      type: 'year',
      active: true,
    }));

    setSelectedPreferences({ subjects: subjects, years: years, country: userCountry, curriculum: userCurriculum });
  }, [userCountry, userSubjects, userYears, userCurriculum]);

  return [selectedPreferences, setSelectedPreferences];
};

export default usePreferences;
