import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import Input from 'components/common/input/Input';

import useStyles from './styles';

const CurriculumSelector = ({ newPreferences, setNewPreferences, curricula }) => {
  const classes = useStyles();

  const handleCurriculumChange = useCallback((selectedCurriculum) => {
    const newCurriculum = curricula.find(curriculum => curriculum.id === selectedCurriculum);
    setNewPreferences({
      ...newPreferences,
      years: [],
      subjects: [],
      curriculum: newCurriculum,
    });
  }, [newPreferences, setNewPreferences, curricula]);

  const curriculaItems = curricula.map(curriculum => ({
    value: curriculum.id,
    label: lang.curricula[curriculum.name],
  }));

  return (
    <div>
      <div className={classes.label}>
        {lang.profile.profilePreferences.curriculum}
      </div>
      <Input
        type="select"
        value={curriculaItems.find(item => item.value === newPreferences.curriculum?.id)}
        multiple={false}
        options={curriculaItems}
        onChange={(e) => handleCurriculumChange(e.value)}
      />
    </div>
  );
};

CurriculumSelector.propTypes = {
  newPreferences: PropTypes.object,
  setNewPreferences: PropTypes.func,
  curricula: PropTypes.array,
};

export default CurriculumSelector;
