import React, { Fragment, useState } from 'react';
import { Wrapper } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';

import { selectUserFeatureToggles } from 'actions/userActions';
import useFeature from 'hooks/useFeature';
import routes from 'routes';
import toggles from 'toggles';

import Error from '../Error';
import Navbar from '../Navbar';
import Sidebar from '../sidebar/Sidebar';
import Loading from 'components/common/Loading';

import useStyles from './styles';

const Layout = ({ children }) => {
  const classes = useStyles();
  const appLoading = useSelector(state => state.page.appLoading);
  const pageLoading = useSelector(state => state.page.pageLoading);
  const layout = useSelector(state => state.page.layout);
  const error = useSelector(state => state.page.error);
  const maintenanceToggle = useFeature(toggles.maintenance);
  const location = useLocation();
  const [openSidebar, setOpenSidebar] = useState(false);
  const userFeatureToggles = useSelector(selectUserFeatureToggles);

  const getPageContent = () => {
    if (error) {
      return (
        <Error code={error} />
      );
    }

    return children;
  };

  if (appLoading || !userFeatureToggles) {
    return (
      <Loading active={appLoading} fullPage />
    );
  }

  if (maintenanceToggle && location.pathname !== routes.maintenance.def) {
    return (
      <Redirect to={routes.maintenance.def} />
    );
  }

  return (
    <Fragment>
      <Render when={layout}>
        <Navbar open={openSidebar} setOpen={setOpenSidebar} />
      </Render>
      <Render when={layout}>
        <Sidebar open={openSidebar} setOpen={setOpenSidebar} />
      </Render>
      <div className={cx(classes.main, { layoutWidth: layout })}>
        <Loading active={pageLoading} fullPage />
        <Wrapper
          fluid
          className={cx(classes.layoutWrapper, {
            [classes.navbarSpacer]: layout,
            [classes.sidebarSpacer]: layout,
            [classes.loading]: pageLoading,
          })}
        >
          {getPageContent()}
        </Wrapper>
      </div>
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
