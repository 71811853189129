import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  pageContainer: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  langSelectorContainer: {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  cardContainer: {
    margin: '100px 30%',
  },
  card: {
    width: '100%',
    padding: '30px',
    boxShadow: `0 0 5px ${theme.shadowColor}`,
    backgroundColor: theme.backgroundColor,
    borderRadius: '18px',
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  header: {
    paddingTop: '10px',
    marginBottom: '24px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  subtitle1: {
    color: theme.textColor2,
  },
  schoolName: {
    color: theme.textColor2,
    marginLeft: '5px',
    fontWeight: 600,
  },
  subtitle2: {
    color: theme.textColor2,
    marginLeft: '5px',
  },
  loginStrategiesSeparator: {
    marginTop: '20px',
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  },
  separatorLine: {
    width: '100%',
    borderBottom: '1px solid #ddd',
  },
  input: {
    marginTop: '20px',
  },
  button: {
    marginTop: '20px',
    marginBottom: '5px',
    border: 'none',
  },
  separator: {
    margin: '20px 25%',
    borderBottom: `1px solid ${theme.textColor2}`,
  },
  createAccountContainer: {
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '14px',
    display: 'flex',
  },
  registerLink: {
    marginLeft: '5px',
    color: '#83cafd',
    cursor: 'pointer',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    pageContainer: {
      flexDirection: 'column',
      backgroundColor: theme.primaryColor,
    },
    cardContainer: {
      width: 'auto',
    },
    logoContainer: {
      alignItems: 'start',
      width: '100%',
      paddingTop: '7vh',
    },
    formContainer: {
      width: '100%',
      marginTop: '3vh',
    },
    loginContainer: {
      margin: '20px 10%',
    },
  },
}));
