import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  card: {
    backgroundColor: theme.backgroundColor,
    width: '100% !important',
    height: 'fit-content',
  },
  infoCard: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 20px 20px 20px',
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    infoCard: {
      display: 'flex',
      flexDirection: 'column',
      margin: '10px 15px 10px 15px',
    },
  },
  [`@media (max-width: ${theme.md}px)`]: {
    card: {
      width: '100% !important',
    },
  },
}));
