import React, { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectUserLang } from 'actions/userActions';
import api from 'api';
import { AZURE_SID, LOGIN_STRATEGY } from 'constants/cookies';
import { MICROSOFT_STRATEGY } from 'constants/loginStrategies';
import useApi from 'hooks/useApi';
import lang from 'lang';
import routes from 'routes';
import { addCookie } from 'utils';

import Loading from 'components/common/Loading';

const MicrosoftRegisterRedirect = () => {
  const { inProgress, accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0]);
  const history = useHistory();
  const locale = useSelector(selectUserLang);
  const toast = useToast();
  const [registerRequest] = useApi(api.register, true);

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      instance.handleRedirectPromise().then((response) => {
        const payload = {
          email: account.username,
          name: account.name,
          loginStrategy: MICROSOFT_STRATEGY,
          oid: account.idTokenClaims.oid,
          locale: locale,
        };

        if (response && response.state) {
          payload.schoolToken = response.state;
        }

        registerRequest([], payload, ({ data }) => {
          if (data.status === 0) {
            addCookie(LOGIN_STRATEGY, MICROSOFT_STRATEGY);
            addCookie(AZURE_SID, account.idTokenClaims.sid);
            window.location = routes.tests.ref();
          } else if (data.status === 2) {
            toast.warning(lang.register.emailExistsError);
            history.push(routes.register.ref());
          } else {
            toast.error(lang.oops);
            history.push(routes.register.ref());
          }
        });
      });
    } else if (!isAuthenticated && inProgress === InteractionStatus.None) {
      history.push(routes.register.ref());
    }
  }, [isAuthenticated, inProgress, account, history, locale, toast, instance, registerRequest]);

  return (
    <Loading
      active
      fullPage
    />
  );
};

export default MicrosoftRegisterRedirect;
