import React, { Fragment, useCallback, useEffect } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';

import api from 'api';
import { logoutRequest as LogoutMSFTRequest } from 'authConfig';
import { AZURE_SID, LOGIN_STRATEGY, SESSION_NONCE, STUDENT_LOGIN_STRATEGY } from 'constants/cookies';
import { LOCAL } from 'constants/environments';
import { LOCAL_STRATEGY, MICROSOFT_STRATEGY, STUDENT_MICROSOFT_STRATEGY } from 'constants/loginStrategies';
import useApi from 'hooks/useApi';
import useQuery from 'hooks/useQuery';
import routes from 'routes';
import { getCookie, removeCookie } from 'utils';

import Loading from 'components/common/Loading';

const Logout = () => {
  const [logoutRequest] = useApi(api.logout);
  const [remoteLogoutRequest] = useApi(api.remoteLogout);
  const history = useHistory();
  const query = useQuery();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const clearSession = useCallback(() => {
    removeCookie(AZURE_SID);
    removeCookie(SESSION_NONCE);
    removeCookie(LOGIN_STRATEGY);
    removeCookie(STUDENT_LOGIN_STRATEGY);
    localStorage.clear();
  }, []);

  const postLogout = useCallback((redirectUri) => {
    clearSession();
    if (redirectUri) {
      window.location = redirectUri;
    } else {
      history.push(routes.home.ref());
    }
  }, [clearSession, history]);

  const onMessage = useCallback((e) => {
    if (e.origin !== routes.tests.ref() || e.data !== 'cleared') {
      return;
    }

    const loginStrategy = getCookie(LOGIN_STRATEGY);
    const studentLoginStrategy = getCookie(STUDENT_LOGIN_STRATEGY);

    const redirectUri = query().get('redirectUri');

    if (loginStrategy === MICROSOFT_STRATEGY || studentLoginStrategy === STUDENT_MICROSOFT_STRATEGY) {
      clearSession();
      instance.logoutRedirect({
        account: account,
        state: redirectUri,
        ...LogoutMSFTRequest,
      });
    }

    if (loginStrategy === LOCAL_STRATEGY) {
      logoutRequest([], null, ({ data }) => {
        if (data.status === 0) {
          postLogout(redirectUri);
        }
      });
    }

    if (loginStrategy === LOCAL_STRATEGY && process.env.REACT_APP_NODE_ENV === LOCAL) {
      remoteLogoutRequest([], null, ({ data }) => {
        if (data.status === 0) {
          postLogout(redirectUri);
        }
      });
    }
  }, [account, instance, logoutRequest, query, clearSession, postLogout, remoteLogoutRequest]);

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  return (
    <Fragment>
      <Loading
        active
        fullPage
      />
      <iframe
        hidden
        src={routes.testsLogout.ref()}
        title="tests"
      />
    </Fragment>
  );
};

export default Logout;
