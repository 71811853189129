import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showLayout } from 'actions/pageActions';
import { selectUserLang } from 'actions/userActions';
import toggles from 'toggles';
import { getErrorCode } from 'utils';

import useFeature from './useFeature';

const usePageLogic = (title, layout) => {
  const lang = useSelector(selectUserLang);
  const iaveToggle = useFeature(toggles.iave);
  const setPageTitle = useCallback((title) => document.title = iaveToggle ? `${title} | IAVE` : `${title} | Intuitivo`, [iaveToggle]);
  const dispatch = useDispatch();

  const [pageLoading, setPageLoading] = useState(true);
  const [pageError, _setPageError] = useState(null);

  useEffect(() => {
    dispatch(showLayout(layout));
  }, [dispatch, layout]);

  const loaded = useCallback(() => {
    setPageLoading(false);
  }, []);

  const setPageError = useCallback((code) => {
    _setPageError(getErrorCode(code));
  }, []);

  if (title) {
    document.title = title + (iaveToggle ? ' | IAVE' : ' | Intuitivo');
  } else {
    document.title = iaveToggle ? 'IAVE' : 'Intuitivo';
  }

  return {
    lang,
    pageLoading,
    loaded,
    setPageTitle,
    pageError,
    setPageError,
  };
};

export default usePageLogic;
