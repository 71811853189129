import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  microsoftButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    color: theme.textColor,
    boxShadow: `0 0 5px ${theme.shadowColor}`,
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
  continueWith: {
    marginLeft: '10px',
  },
  fullName: {
    marginLeft: '5px',
    fontWeight: 600,
  },
}));
