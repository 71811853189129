import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { pageError } from 'actions/pageActions';
import lang from 'lang';
import routes from 'routes';

import Button from 'components/common/Button';

import useStyles from './styles';

const Error = ({ code }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getErrorMessage = () => {
    switch (code) {
      case '403':
        return lang.unauthorizedMessage;
      case '404':
        return lang.notFoundMessage;
      case '500':
        return lang.errorMessage;
      default:
        return '';
    }
  };

  const _onClick = () => {
    dispatch(pageError(null));
    window.location.replace(routes.home.ref());
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.code}>
        {code}
      </div>
      <div className={classes.message}>
        {getErrorMessage()}
      </div>
      <Link
        to={routes.home.ref()}
        className={classes.homeLink}
      >
        <Button
          className={classes.homeButton}
          onClick={() => _onClick()}
        >
          <FontAwesomeIcon
            icon={faHome}
            className={classes.homeButtonIcon}
          />
          {lang.home}
        </Button>
      </Link>
    </div>
  );
};

Error.propTypes = {
  code: PropTypes.string.isRequired,
};

export default Error;
