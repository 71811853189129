import React, { useEffect, useState } from 'react';
import { faCcMastercard, faCcVisa, faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectPaymentMethod, setPaymentMethod } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import lang from 'lang';

import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const PaymentMethodsModal = ({ open, close }) => {
  const classes = useStyles();
  const paymentMethod = useSelector(selectPaymentMethod);
  const [deletePaymentMethodRequest] = useApi(api.deletePaymentMethod);
  const [replacePaymentMethodRequest] = useApi(api.replacePaymentMethod);
  const [buttonState, setButtonState] = useState(false);
  const [replaceState, setReplaceState] = useState(false);
  const [addPaymentMethodRequest] = useApi(api.addPaymentMethod);
  const [getPaymentMethodRequest] = useApi(api.getPaymentMethod);
  const toast = useToast();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const _close = () => {
    setTimeout(() => {
      setButtonState(false);
      setReplaceState(false);
    }, 200);
    close();
  };

  useEffect(() => {
    if (open && (!paymentMethod || replaceState) && elements) {
      const paymentElement = elements.getElement('payment') || elements.create('payment', { layout: { type: 'tabs', defaultCollapsed: false } });
      paymentElement.mount('#payment-element');
    }
  }, [paymentMethod, elements, open, replaceState]);

  const getBrandIcon = () => {
    let icon;
    if (paymentMethod?.paypal) {
      icon = faPaypal;
    } else {
      switch (paymentMethod?.card.brand) {
        case 'visa':
          icon = faCcVisa;
          break;
        case 'mastercard':
          icon = faCcMastercard;
          break;
        default:
          icon = faCreditCard;
          break;
      }
    }
    return (
      <FontAwesomeIcon icon={icon} />
    );
  };

  const getPaymentInfo = () => {
    if (paymentMethod?.paypal) {
      return (
        <div className={classes.info}>
          {lang.profile.plans.paypalEmail + paymentMethod?.paypal.payer_email}
        </div>
      );
    } else if (paymentMethod?.card) {
      return (
        <div className={classes.info}>
          <div className={classes.endingIn}>
            {lang.profile.plans.cardEndingIn(paymentMethod?.card.brand[0].toUpperCase() + paymentMethod?.card.brand.slice(1), paymentMethod?.card.last4)}
          </div>
          <div className={classes.expires}>
            {lang.profile.plans.expires + ((paymentMethod?.card.exp_month < 10 ? '0' + paymentMethod?.card.exp_month : paymentMethod?.card.exp_month) + '/' + paymentMethod?.card.exp_year)}
          </div>
        </div>
      );
    }
    return null;
  };

  const removeMethod = () => {
    setLoading(true);
    deletePaymentMethodRequest([], null, async ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          setLoading(false);
          toast.success(lang.changesSaved);
          dispatch(setPaymentMethod(null));
        }, 200);
      } else {
        toast.error(lang.oops);
      }
      setLoading(false);
      close();
    });
  };

  const addPaymentMethod = async () => {
    setLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setLoading(false);
      return;
    }

    addPaymentMethodRequest([], {}, async ({ data }) => {
      if (data.status === 0) {
        if (data.intent.client_secret) {
          const result = await stripe.confirmSetup({
            elements,
            clientSecret: data.intent.client_secret,
            redirect: 'if_required',
            confirmParams: {
              return_url: window.location.href,
            },
          });

          if (result.error) {
            setLoading(false);
            toast.error(result.error.message);
            return;
          } else if (result.setupIntent) {
            getPaymentMethodRequest([], null, ({ data }) => {
              if (data.status === 0) {
                dispatch(setPaymentMethod(data.paymentMethod));
              }
            });
          }

          setLoading(false);
          setTimeout(() => {
            toast.success(lang.changesSaved);
          }, 200);
        }
      } else {
        toast.error(lang.oops);
      }

      _close();
    });
  };

  const replacePaymentMethod = async () => {
    setLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setLoading(false);
      return;
    }

    replacePaymentMethodRequest([], {}, async ({ data }) => {
      if (data.status === 0) {
        if (data.intent.client_secret) {
          const result = await stripe.confirmSetup({
            elements,
            clientSecret: data.intent.client_secret,
            redirect: 'if_required',
            confirmParams: {
              return_url: window.location.href,
            },
          });

          if (result.error) {
            setLoading(false);
            toast.error(result.error.message);
            return;
          } else if (result.setupIntent) {
            getPaymentMethodRequest([], null, ({ data }) => {
              if (data.status === 0) {
                dispatch(setPaymentMethod(data.paymentMethod));
              }
            });
          }

          setLoading(false);
          setTimeout(() => {
            toast.success(lang.changesSaved);
          }, 200);
        }
      } else {
        toast.error(lang.oops);
      }

      _close();
    });
  };

  const actions = (paymentMethod && !replaceState) ? [] : [
    {
      name: lang.confirm,
      color: 'red',
      onClick: replaceState ? replacePaymentMethod : addPaymentMethod,
      loading: loading,
      hide: true,
    },
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
      hide: true,
    },
  ];

  if (!paymentMethod && (!stripe || !elements)) {
    return false;
  }

  return (
    <Modal
      open={open}
      close={_close}
      header={lang.profile.plans.paymentMethod}
      className={classes.plansModal}
      center
      transition
      medium
      actions={actions}
    >
      <Render when={paymentMethod && !replaceState}>
        <div className={classes.methodCard}>
          <div className={classes.methodCardLeft}>
            <div className={classes.logo}>
              {getBrandIcon()}
            </div>
            {getPaymentInfo()}
          </div>
          <div className={classes.actions}>
            <Render when={!buttonState}>
              <Tooltip tip={lang.profile.plans.replacePaymentMethod}>
                <Button
                  className={classes.button}
                  onClick={() => setReplaceState(true)}
                  sibling
                >
                  <FontAwesomeIcon icon={faPen} className={classes.icon} />
                </Button>
              </Tooltip>
              <Tooltip tip={lang.profile.plans.removePaymentMethod}>
                <Button
                  className={classes.button}
                  onClick={() => setButtonState(true)}
                  sibling
                >
                  <FontAwesomeIcon icon={faTrash} className={classes.icon} />
                </Button>
              </Tooltip>
            </Render>
            <Render when={buttonState}>
              <Button
                className={classes.button}
                onClick={removeMethod}
                red
                sibling
                loading={loading}
              >
                {lang.confirm}
              </Button>
              <Button
                className={classes.button}
                onClick={() => setButtonState(false)}
                sibling
              >
                {lang.cancel}
              </Button>
            </Render>

          </div>
        </div>
      </Render>
      {(!paymentMethod || replaceState) ?
        <div id="payment-element"></div>
        : null}
    </Modal>
  );
};

PaymentMethodsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default PaymentMethodsModal;
