import React, { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal, useAccount } from '@azure/msal-react';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useHistory } from 'react-router-dom';

import api from 'api';
import { AZURE_SID, LOGIN_STRATEGY } from 'constants/cookies';
import { MICROSOFT_STRATEGY } from 'constants/loginStrategies';
import useApi from 'hooks/useApi';
import lang from 'lang';
import routes from 'routes';
import { addCookie, removeCookie } from 'utils';

import Loading from 'components/common/Loading';

const MicrosoftLoginRedirect = () => {
  const { inProgress, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  const [checkUserRequest] = useApi(api.checkUser);
  const toast = useToast();

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      addCookie(LOGIN_STRATEGY, MICROSOFT_STRATEGY);
      addCookie(AZURE_SID, account.idTokenClaims.sid);

      checkUserRequest([], { email: account.username }, ({ data }) => {
        if (data.status === 0) {
          window.location = routes.tests.ref();
          return;
        } else if (data.status === 2) {
          toast.warning(lang.login.loginWithMicrosoftRegularAccountExists);
        } else if (data.status === 3) {
          toast.warning(lang.login.loginWithMicrosoftNoAccount);
        }
        removeCookie(LOGIN_STRATEGY);
        removeCookie(AZURE_SID);
        history.push(routes.home.ref());
      });
    } else if (!isAuthenticated && inProgress === InteractionStatus.None) {
      history.push(routes.home.ref());
    }
  }, [isAuthenticated, inProgress, history, account, checkUserRequest, toast]);

  return (
    <Loading
      active
      fullPage
    />
  );
};

export default MicrosoftLoginRedirect;
