import React from 'react';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserSchools } from 'actions/userActions';
import { FREE, PREMIUM, SCHOOLS } from 'constants/plans';
import lang from 'lang';

import useStyles from './styles';

const PlanCard = ({ plan }) => {
  const classes = useStyles();
  const userSchools = useSelector(selectUserSchools);

  const planDueDate = new Date(plan.nextPayment);
  const planDueDateDay = planDueDate.getDate();
  const planDueDateMonth = planDueDate.getMonth() + 1;
  const planDueDateYear = planDueDate.getFullYear();

  const getPlanType = () => {
    switch (plan.name) {
      case FREE:
        return lang.profile.plans.basic;
      case PREMIUM:
        return lang.profile.plans.premium;
      case SCHOOLS:
        return lang.profile.plans.schools;
      default:
        break;
    }
  };

  const getPriceLabel = () => {
    switch (plan.name) {
      case FREE:
        return lang.profile.plans.free;
      case PREMIUM:
        return plan.price ? (plan.price + '€') : lang.profile.plans.premiumUser;
      case SCHOOLS:
        return plan.isProfileCard ? userSchools.map(school => school.name).join('|') : lang.profile.plans.onRequest;
      default:
        break;
    }
  };

  const getPlanTarget = () => {
    switch (plan.name) {
      case FREE:
        return lang.profile.plans.forTeachers;
      case PREMIUM:
        return lang.profile.plans.forTeachers;
      case SCHOOLS:
        return lang.profile.plans.forSchools;
      default:
        break;
    }
  };

  const getSchools = () => {
    if ([FREE, PREMIUM].includes(plan.name)) {
      return lang.appKeywords.personalSpace;
    }
  };

  return (
    <div className={cx(classes.card, { free: plan.name === FREE, premium: plan.name === PREMIUM, schools: plan.name === SCHOOLS })}>
      <div className={classes.header}>
        <div className={cx(classes.planType, { free: plan.name === FREE, premium: plan.name === PREMIUM, schools: plan.name === SCHOOLS })}>
          {getPlanType()}
        </div>
        <div className={classes.planTarget}>
          {getPlanTarget()}
        </div>
      </div>
      <div className={classes.planSchools}>
        {getSchools()}
      </div>
      <div className={classes.planPrice}>
        {getPriceLabel()}
        <Render when={plan.name === PREMIUM && plan.price}>
          <div className={classes.perMonth}>
            {'/' + lang.month}
          </div>
        </Render>
      </div>
      <Render when={plan.name === PREMIUM && plan.subscriptionType === 'yearly'}>
        <div className={classes.yearlyExtraText}>
          {`(${plan.price * 12}€ ${lang.profile.plans.billedYearly})`}
        </div>
      </Render>
      <div className={classes.planDescription}>
        {lang.profile.plans.planDescription[plan.name]}
      </div>
      <Render when={plan.name !== FREE && plan.nextPayment}>
        <div className={classes.dueDate}>
          <div className={classes.priceInfo}>
            {plan.active ? lang.profile.plans.nextPayment : lang.profile.plans.subscribedUntil}
            {':'}
          </div>
          <div className={classes.name}>
            {planDueDateDay}
            {'/'}
            {planDueDateMonth}
            {'/'}
            {planDueDateYear}
          </div>
        </div>
      </Render>
    </div>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.object,
};

export default PlanCard;
