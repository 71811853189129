import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  profilePageWrapper: {
    margin: '35px',
    display: 'grid',
    gap: '50px',
    gridTemplateColumns: '361px 361px',
    gridAutoFlow: 'dense',
    justifyContent: 'center',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    profilePageWrapper: {
      gridTemplateColumns: '361px',
    },
  },
}));
