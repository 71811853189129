import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUserSubjects, selectUserYears } from 'actions/userActions';
import api from 'api';
import { sortByActiveAndName } from 'utils';

import useApi from './useApi';

const usePreferencesOptions = (userCurriculumId) => {
  const userSubjects = useSelector(selectUserSubjects);
  const userYears = useSelector(selectUserYears);
  const [getSubjectsRequest] = useApi(api.getSubjects);
  const [getYearsRequest] = useApi(api.getYears);
  const [getCurriculaRequest] = useApi(api.getCurricula);

  const [subjects, setSubjects] = useState([]);
  const [years, setYears] = useState([]);
  const [curricula, setCurricula] = useState([]);

  useEffect(() => {
    if (!userCurriculumId) {
      return;
    }

    getSubjectsRequest([userCurriculumId], null, ({ data }) => {
      const newSubjects = data.subjects.map(subject => ({
        ...subject,
        color: '#CB6EB7',
        hoverColor: '#B4419B',
        type: 'subject',
        active: !!userSubjects.find(el => el.id === subject.id),
        curriculumId: subject.curriculumId,
      }));

      setSubjects(sortByActiveAndName(newSubjects));
    });

    getYearsRequest([userCurriculumId], null, ({ data }) => {
      const newYears = data.years.map(year => ({
        ...year,
        color: '#F79649',
        hoverColor: '#EB6F0A',
        type: 'year',
        active: !!userYears.find(el => el.id === year.id),
        curriculumId: year.curriculumId,
      }));

      setYears(sortByActiveAndName(newYears));
    });
  }, [getSubjectsRequest, getYearsRequest, userSubjects, userYears, userCurriculumId, getCurriculaRequest]);

  useEffect(() => {
    getCurriculaRequest([], null, ({ data }) => {
      if (data.status === 0) {
        setCurricula(data.curricula);
      }
    });
  }, [getCurriculaRequest]);

  return [subjects, years, curricula];
};

export default usePreferencesOptions;
