import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import useInput from 'hooks/useInput';
import usePageLogic from 'hooks/usePageLogic';
import lang from 'lang';

import Button from 'components/common/Button';
import Input from 'components/common/input/Input';

import useStyles from './styles';

const RegisterForm = ({ handleRegister, loading }) => {
  const classes = useStyles();
  const { loaded } = usePageLogic(lang.register.header, false);

  const [name, setName, nameErrors, setNameErrors] = useInput('');
  const [email, setEmail, emailErrors, setEmailErrors] = useInput('');
  const [password, setPassword, passwordErrors, setPasswordErrors] = useInput('');
  const [repeatPassword, setRepeatPassword, repeatPasswordErrors, setRepeatPasswordErrors] = useInput('');

  useEffect(() => {
    loaded();
  }, [loaded]);

  const onSubmit = useCallback((event) => {
    event.preventDefault();

    let valid = true;
    const localNameErrors = [];
    const localEmailErrors = [];
    const localPasswordErrors = [];
    const localRepeatPasswordErrors = [];

    if (name.trim() === '') {
      localNameErrors.push(lang.register.nameEmptyError);
      valid = false;
    }

    if (email.trim() === '') {
      localEmailErrors.push(lang.register.emailEmptyError);
      valid = false;
    }

    if (!email.match(/(.+)@(.+){2,}\.(.+){2,}/)) {
      localEmailErrors.push(lang.register.emailInvalidError);
      valid = false;
    }

    if (password.length < 8) {
      localPasswordErrors.push(lang.register.passwordLengthError);
      valid = false;
    }

    if (!/[A-Z]/.test(password)) {
      localPasswordErrors.push(lang.register.passwordUpperCharsError);
      valid = false;
    }

    if (!/[0-9]/.test(password)) {
      localPasswordErrors.push(lang.register.passwordNumberError);
      valid = false;
    }

    if (!/[^\w\s]/.test(password)) {
      localPasswordErrors.push(lang.register.passwordSpecialCharsError);
      valid = false;
    }

    if (password !== repeatPassword) {
      localRepeatPasswordErrors.push(lang.register.passwordsDifferentError);
      valid = false;
    }

    if (!valid) {
      setTimeout(() => {
        setNameErrors(localNameErrors);
        setEmailErrors(localEmailErrors);
        setPasswordErrors(localPasswordErrors);
        setRepeatPasswordErrors(localRepeatPasswordErrors);
      }, 200);
      return;
    }

    setNameErrors([]);
    setEmailErrors([]);
    setPasswordErrors([]);
    setRepeatPasswordErrors([]);

    const newUser = {
      name: name,
      email: email,
      password: password,
      repeatPassword: repeatPassword,
    };

    handleRegister(newUser);
  }, [email, handleRegister, name, password, repeatPassword, setEmailErrors, setNameErrors, setPasswordErrors, setRepeatPasswordErrors]);

  return (
    <form onSubmit={onSubmit}>
      <Input
        type="text"
        name="name"
        value={name}
        onChange={(event) => setName(event.target.value)}
        errors={nameErrors}
        invalid={nameErrors.length !== 0}
        label={lang.register.nameLabel}
        placeholder={lang.register.namePlaceholder}
        className={classes.input}
        required
      />
      <Input
        type="email"
        name="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        errors={emailErrors}
        invalid={emailErrors.length !== 0}
        label={lang.register.emailLabel}
        placeholder={lang.register.emailPlaceholder}
        className={classes.input}
        required
      />
      <Input
        type="password"
        name="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        errors={passwordErrors}
        invalid={passwordErrors.length !== 0}
        label={lang.register.passwordLabel}
        placeholder={lang.register.passwordPlaceholder}
        className={classes.input}
        required
      />
      <Input
        type="password"
        name="repeatPassword"
        value={repeatPassword}
        onChange={(event) => setRepeatPassword(event.target.value)}
        errors={repeatPasswordErrors}
        invalid={repeatPasswordErrors.length !== 0}
        label={lang.register.repeatPasswordLabel}
        placeholder={lang.register.repeatPasswordPlaceholder}
        className={classes.input}
        required
      />
      <Button
        type="submit"
        className={classes.button}
        stretch
        loading={loading}
        disabled={loading}
      >
        {lang.register.createAccount}
      </Button>
    </form>
  );
};

RegisterForm.propTypes = {
  handleRegister: PropTypes.func,
  loading: PropTypes.bool,
};

export default RegisterForm;
