import React, { Fragment } from 'react';

import lang from 'lang';

import LangSelector from 'components/common/LangSelector';
import Error from 'components/common/layout/Error';

import useStyles from './styles';

const NotFound = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.langSelectorContainer}>
        <LangSelector tipDirection="left" />
      </div>
      <Error code="404" message={lang.notFoundMessage} />
    </Fragment>
  );
};

export default NotFound;
