
import React, { useEffect } from 'react';
import { OutlineProvider } from '@intuitivo-pt/outline-ui';
import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { ThemeProvider } from 'react-jss';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import useFeature from 'hooks/useFeature';
import usePublicFeatureToggles from 'hooks/usePublicFeatureToggles';
import useStripePromise from 'hooks/useStripePromise';
import Router from 'Router';
import theme from 'theme';
import toggles from 'toggles';

import AuthHandler from 'components/common/handlers/AuthHandler';
import ToastHandler from 'components/common/handlers/ToastHandler';
import Error from 'components/common/layout/Error';
import Layout from 'components/common/layout/Layout';

const App = () => {
  const themeType = useSelector(state => state.page.isDark) ? 'dark' : 'light';
  const stripePromise = useStripePromise();
  const iaveToggle = useFeature(toggles.iave);

  usePublicFeatureToggles();

  useEffect(() => {
    const link = document.querySelector('link[rel~=\'icon\']');
    if (iaveToggle) {
      link.href = `${process.env.PUBLIC_URL}/favicon-iave.jpg`;
    }
  }, [iaveToggle]);

  const options = {
    mode: 'subscription',
    amount: 0,
    currency: 'eur',
    automatic_payment_methods: {
      enabled: true,
    },
    appearance: {
      theme: 'flat',
      variables: {
        fontFamily: '"Open Sans", sans-serif',
        colorPrimary: '#7DD889',
        colorIconTabSelected: '#fff',
      },
      rules: {
        '.Tab--selected, .Tab--selected:hover': {
          color: '#fff',
        },
      },
    },
  };

  if (!stripePromise) {
    return false;
  }

  return (
    <OutlineProvider>
      <ThemeProvider theme={{ ...theme.general, ...theme[themeType] }}>
        <Elements stripe={stripePromise} options={options} >
          <BrowserRouter>
            <Sentry.ErrorBoundary fallback={<Error code={'500'} />}>
              <Layout>
                <Router />
              </Layout >
              <AuthHandler />
              <ToastHandler />
            </Sentry.ErrorBoundary>
          </BrowserRouter>
        </Elements>
      </ThemeProvider>
    </OutlineProvider>
  );
};

export default App;
