const route = (def, ref) => ({
  def: def,
  ref: ref || (() => def),
});

const getAppUrl = (app) => {
  if (process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'local') {
    const appUrl = `${window.origin.slice(0, window.origin.length - 4)}${app}${window.origin.slice(window.origin.length - 3)}`;
    return appUrl;
  } else {
    const appUrl = `${window.origin.slice(0, window.origin.indexOf('//') + 2)}${app}${window.origin.slice(window.origin.indexOf('.'))}`;
    return appUrl;
  }
};

export default {
  help: route(`${process.env.REACT_APP_WEBSITE_URL}/suporte`),
  feedback: route(`${process.env.REACT_APP_WEBSITE_URL}/feedback`),
  tutorial: route(`${process.env.REACT_APP_WEBSITE_URL}/tutoriais`),
  contact: route(`${process.env.REACT_APP_WEBSITE_URL}/contactos`),
  recommendSchool: route(`${process.env.REACT_APP_WEBSITE_URL}/recomendar-instituicao`),

  tests: route(null, (spaceId, groupId) => `${getAppUrl(process.env.REACT_APP_TESTS_APP)}${spaceId ? `/s/${spaceId}` : ''}${groupId ? `?group=${groupId}` : ''}`),
  exercises: route(null, (spaceId) => `${getAppUrl(process.env.REACT_APP_TESTS_APP)}${spaceId ? `/s/${spaceId}` : '/s/p'}/exercises`),
  groups: route(null, (spaceId) => `${getAppUrl(process.env.REACT_APP_TESTS_APP)}${spaceId ? `/s/${spaceId}` : '/s/p'}/groups`),
  rubrics: route(null, (spaceId) => `${getAppUrl(process.env.REACT_APP_TESTS_APP)}${spaceId ? `/s/${spaceId}` : '/s/p'}/rubrics`),
  explore: route(null, (spaceId) => `${getAppUrl(process.env.REACT_APP_TESTS_APP)}${spaceId ? `/s/${spaceId}` : '/s/p'}/explore`),
  testsLogout: route(null, () => `${getAppUrl(process.env.REACT_APP_TESTS_APP)}/logout`),
  admin: route(getAppUrl(process.env.REACT_APP_ADMIN_APP)),
  events: route(`${getAppUrl(process.env.REACT_APP_ADMIN_APP)}/events`),
  students: route(`${getAppUrl(process.env.REACT_APP_ADMIN_APP)}/students`),
  synchronization: route(`${getAppUrl(process.env.REACT_APP_ADMIN_APP)}/synchronization`),
  teachers: route(`${getAppUrl(process.env.REACT_APP_ADMIN_APP)}/teachers`),
  exams: route(`${getAppUrl(process.env.REACT_APP_ADMIN_APP)}/exams`),

  profile: route('/user', (plan, subscriptionType) => `/user${plan ? `?plan=${plan}` : ''}${plan && subscriptionType ? `&type=${subscriptionType}` : ''}`),

  home: route('/'),
  onboarding: route('/onboarding'),
  recover: route('/recover'),
  register: route('/register'),
  schoolInvite: route('/school-invite'),
  registerMicrosoft: route('/register/microsoft'),
  loginMicrosoft: route('/login/microsoft'),
  linkMicrosoft: route('/link/microsoft'),
  logoutMicrosoft: route('/logout/microsoft'),
  logout: route('/logout'),
  maintenance: route('/maintenance'),
  limbo: route('/limbo'),
};
