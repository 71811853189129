import React, { useEffect, useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import countries from 'countries';
import { useSelector } from 'react-redux';

import { selectUserSpaceIdPreference } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import useFeature from 'hooks/useFeature';
import usePageLogic from 'hooks/usePageLogic';
import usePreferences from 'hooks/usePreferences';
import usePreferencesOptions from 'hooks/usePreferencesOptions';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { addCookie, getCookie } from 'utils';

import Button from 'components/common/Button';
import LangSelector from 'components/common/LangSelector';
import Logo from 'components/common/Logo';
import CountrySelector from 'components/profile/CountrySelector';
import CurriculumSelector from 'components/profile/CurriculumSelector';
import SubjectsSelector from 'components/profile/SubjectsSelector';
import YearsSelector from 'components/profile/YearsSelector';

import useStyles from './styles';

const Onboarding = () => {
  const classes = useStyles();
  const { loaded } = usePageLogic(lang.onboarding.title, false);
  const [startTourRequest] = useApi(api.startTourTest);
  const currentSchoolId = useSelector(selectUserSpaceIdPreference);
  const toast = useToast();
  const teacherOnboardingToggle = useFeature(toggles.teacherOnboarding);
  const exploreCurriculum = useFeature(toggles.exploreCurriculum);
  const [editPreferencesRequest] = useApi(api.editPreferences);
  const sessionLang = getCookie('session_lang');
  const [preferences, setPreferences] = usePreferences();
  const [subjects, years, curricula] = usePreferencesOptions(preferences.curriculum?.id);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const defaultCountry = countries.find(country => country.code === (sessionLang === 'pt' ? 'PT' : 'GB'));
    const defaultCurriculum = curricula.find(curriculum => curriculum.country === defaultCountry.code) ?? curricula.find(curriculum => curriculum.name === 'other');
    if (defaultCountry) {
      setPreferences(prev => ({
        ...prev,
        country: defaultCountry.code,
      }));
    }
    if (defaultCurriculum) {
      setPreferences(prev => ({
        ...prev,
        curriculum: defaultCurriculum,
      }));
    }
  }, [sessionLang, setPreferences, curricula]);

  const redirectToTests = () => window.location = routes.tests.ref();

  const startTourAndRedirect = () => {
    setLoading(true);
    if (teacherOnboardingToggle) {
      startTourRequest([], { schoolId: currentSchoolId }, ({ data: { status, test } }) => {
        if (status === 0) {
          addCookie('tour_test_id', test.id);
          redirectToTests();
          return;
        }

        setLoading(false);
        toast.error(lang.oops);
      });
    } else {
      redirectToTests();
    }
  };

  const savePreferences = () => {
    setLoading(true);
    const newPreferences = {
      ...preferences,
      isFirstTime: false,
      curriculum: preferences.curriculum?.id,
    };

    editPreferencesRequest([], newPreferences, ({ data }) => {
      if (data.status === 0) {
        startTourAndRedirect();
        return;
      }

      setLoading(false);
      toast.error(lang.oops);
    });
  };

  useEffect(() => {
    loaded();
  }, [loaded]);

  return (
    <div className={classes.pageContainer}>
      <div className={classes.langSelectorContainer}>
        <LangSelector tipDirection="left" />
      </div>
      <div className={classes.logoContainer}>
        <Logo large dark />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.card}>
          <div className={classes.loginHeader}>
            {lang.onboarding.header}
          </div>
          {exploreCurriculum &&
          <div>
            <Spacer px={20} />
            <CountrySelector
              newPreferences={preferences}
              setNewPreferences={setPreferences}
            />
            <Spacer px={20} />
            <CurriculumSelector
              newPreferences={preferences}
              setNewPreferences={setPreferences}
              curricula={curricula}
            />
          </div>
          }
          <Spacer px={20} />
          <SubjectsSelector
            newPreferences={preferences}
            setNewPreferences={setPreferences}
            subjects={subjects}
          />
          <Spacer px={20} />
          <YearsSelector
            newPreferences={preferences}
            setNewPreferences={setPreferences}
            years={years}
          />
          <Spacer px={40} />
          <div className={classes.buttonsContainer}>
            <Button onClick={() => savePreferences(true)} loading={loading}>
              {lang.save}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
