import React, { useState } from 'react';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, DropdownMenuItem } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles from './styles';

const SecondaryPageHeader = ({ header, extraInformations, actions, extraActions }) => {
  const classes = useStyles();

  const [dropdown, setDropdown] = useState(false);

  const getExtraInformations = () => {
    if (!extraInformations) {
      return;
    }

    return extraInformations.map((info) => (
      <div
        key={info.label}
        className={classes.description}
      >
        <Render when={info.icon}>
          <div className={classes.descriptionIcon}>
            <FontAwesomeIcon
              icon={info.icon}
            />
          </div>
        </Render>
        {info.label}
      </div>
    ));
  };

  const getActions = () => {
    if (!actions) {
      return;
    }

    return actions.map((action) => (
      <div
        key={action.label}
        className={cx(classes.actionWrap, { active: action.active, disabled: action.disabled })}
        onClick={action.disabled ? () => { } : action.onClick}
      >
        <FontAwesomeIcon
          icon={action.icon}
        />
        <div className={classes.actionTitle}>
          {action.label}
        </div>
      </div>
    ));
  };

  const getExtraActions = () => {
    if (!extraActions || extraActions.length === 0) {
      return;
    }

    return (
      <Dropdown
        close={() => setDropdown(false)}
        left
      >
        <div
          className={classes.extraActions}
          onClick={() => setDropdown(!dropdown)}
        >
          <FontAwesomeIcon
            icon={faEllipsisV}
          />
        </div>
        <DropdownMenu
          open={dropdown}
          className={classes.extraActionsDropdownMenu}
        >
          {extraActions.map((action) => (
            <DropdownMenuItem
              key={action.label}
              className={classes.extraActionsDropdownItem}
              onClick={action.onClick}
            >
              {action.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerSplitter}>
        <div className={classes.headerLeft}>
          <div className={classes.header}>
            {header}
          </div>
          {getExtraInformations()}
        </div>
        <div className={classes.headerRight}>
          <div className={classes.headerActions}>
            {getActions()}
            {getExtraActions()}
          </div>
        </div>
      </div>
    </div>
  );
};

SecondaryPageHeader.propTypes = {
  header: PropTypes.string,
  extraInformations: PropTypes.array,
  actions: PropTypes.array,
  extraActions: PropTypes.array,
};

export default SecondaryPageHeader;
