import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectUserIsTeacher, selectUserPlan } from 'actions/userActions';
import useFeature from 'hooks/useFeature';
import usePageLogic from 'hooks/usePageLogic';
import lang from 'lang';
import toggles from 'toggles';

import SecondaryPageHeader from 'components/common/layout/SecondaryPageHeader';
import DangerCard from 'components/profile/DangerCard';
import ProfileInformationCard from 'components/profile/ProfileInformationCard';
import ProfilePlansCard from 'components/profile/ProfilePlansCard';
import ProfilePreferencesCard from 'components/profile/ProfilePreferencesCard';
import SecurityCard from 'components/profile/SecurityCard';

import useStyles from './styles';

const Profile = () => {
  const classes = useStyles();
  const { loaded } = usePageLogic('Profile', true);
  const exploreToggle = useFeature(toggles.explore);
  const plansToggle = useFeature(toggles.plans);
  const isTeacher = useSelector(selectUserIsTeacher);
  const userPlan = useSelector(selectUserPlan);
  const exploreCurriculumToggle = useFeature(toggles.exploreCurriculum);

  useEffect(() => {
    loaded();
  }, [loaded]);

  const descriptions = [
    {
      label: lang.profile.description,
    },
  ];

  return (
    <Fragment>
      <SecondaryPageHeader
        header={lang.profile.header}
        extraInformations={descriptions}
      />
      <div className={classes.profilePageWrapper}>
        <ProfileInformationCard />
        {plansToggle && isTeacher && userPlan && (
          <ProfilePlansCard />
        )}
        {exploreToggle && isTeacher && (
          <ProfilePreferencesCard />
        )}
        <SecurityCard />
        {exploreCurriculumToggle && isTeacher && (
          <DangerCard />
        )}
      </div>
    </Fragment>
  );
};

export default Profile;
