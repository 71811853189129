import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  card: {
    backgroundColor: theme.backgroundColor,
    width: '100% !important',
    height: 'fit-content',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 20px 20px 20px',
  },
  sectionHeader: {
    marginBottom: '20px',
    fontWeight: 600,
  },
  label: {
    fontWeight: 600,
  },
  [`@media (max-width: ${theme.md}px)`]: {
    card: {
      width: '100% !important',
    },
  },
}));
