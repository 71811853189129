import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';

import { selectUserLang } from 'actions/userActions';

const useStripePromise = () => {
  const locale = useSelector(selectUserLang);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
    locale: locale,
  });

  return stripePromise;
};

export default useStripePromise;
