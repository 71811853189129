import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles from 'hooks/useStyles';

import styles from './styles';

const ALink = ({ href, target, icon, children, className }) => {
  const classes = useStyles(styles);

  return (
    <a
      href={href}
      target={target}
      className={cx(classes.aLink, className)}
    >
      <Render when={icon}>
        <FontAwesomeIcon className={classes.icon} icon={icon} />
      </Render>
      <div className={cx(classes.linkChildren, { icon })}>
        {children}
      </div>
    </a>
  );
};

ALink.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default ALink;
