import React from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { unlinkMicrosoftAccount } from 'actions/userActions';
import api from 'api';
import { LOGIN_STRATEGY } from 'constants/cookies';
import { LOCAL_STRATEGY, MICROSOFT_STRATEGY } from 'constants/loginStrategies';
import useApi from 'hooks/useApi';
import lang from 'lang';
import routes from 'routes';
import { getCookie } from 'utils';

import Modal from 'components/common/Modal';

const UnlinkMicrosoftAccountModal = ({ open, close }) => {
  const toast = useToast();
  const [unlinkMicrosoftAccountRequest, loading] = useApi(api.unlinkMicrosoftAccount);
  const history = useHistory();
  const dispatch = useDispatch();

  const unlinkMicrosoft = () => {
    unlinkMicrosoftAccountRequest([], null, ({ data }) => {
      const loginStrategy = getCookie(LOGIN_STRATEGY);
      if (data.status === 0) {
        if (loginStrategy === MICROSOFT_STRATEGY) {
          history.push(routes.logout.ref());
        } else if (loginStrategy === LOCAL_STRATEGY) {
          dispatch(unlinkMicrosoftAccount());
          toast.success(lang.profile.security.unlinkMicrosoftAccountSuccess);
          close();
        }
      }
    });
  };

  const actions = [
    {
      name: lang.confirm,
      color: 'red',
      onClick: unlinkMicrosoft,
      loading: loading,
    },
    {
      name: lang.cancel,
      color: 'black',
      onClick: close,
    },
  ];

  return (
    <Modal
      open={open}
      close={close}
      header={lang.profile.security.unlinkMicrosoftAccount}
      actions={actions}
      center
      transition
      small
    >
      <div>
        {lang.profile.security.unlinkMicrosoftAccountInfo}
      </div>
    </Modal>
  );
};

UnlinkMicrosoftAccountModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default UnlinkMicrosoftAccountModal;
