
import React, { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useSelector, useDispatch } from 'react-redux';

import { appLoaded } from 'actions/pageActions';
import { bootUser, langSet } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import lang from 'lang';
import { addCookie } from 'utils';

import Loading from 'components/common/Loading';

const AuthHandler = () => {
  const dispatch = useDispatch();
  const appLoading = useSelector(state => state.page.appLoading);
  const [meRequest] = useApi(api.me);
  const { inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      meRequest([], null, ({ data }) => {
        if (data.status === 0) {
          addCookie('session_lang', data.user.lang);
          lang.setLanguage(data.user.lang);

          dispatch(langSet(data.user.lang));
          dispatch(bootUser(data.user));
        }
        dispatch(appLoaded());
      });
    }
  }, [inProgress, dispatch, meRequest]);

  return (
    <Loading active={appLoading} fullPage />
  );
};

export default AuthHandler;
