import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  plansModal: {
    '& [class^="modalHeader"] > div': {
      width: '100%',
    },
  },
  methodCard: {
    backgroundColor: `${theme.green}21`,
    border: `2px solid ${theme.midnightGreen}99`,
    borderRadius: '18px',
    padding: '1em 1.5em',
    display: 'flex',
    gap: '0.5em',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  methodCardLeft: {
    display: 'flex',
    gap: '0.5em',
  },
  info: {
    color: theme.midnightGreen,
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
  },
  logo: {
    fontSize: '20px',
    color: theme.midnightGreen,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: theme.midnightGreen,
    gap: '0.5em',
  },
  button: {
    backgroundColor: `${theme.midnightGreen}E6`,
    '&:hover': {
      backgroundColor: theme.midnightGreen,
    },
  },
  icon: {
    fontSize: '18px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
