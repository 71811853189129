import React from 'react';
import { Spacer } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';

import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import ChangePassword from '../ChangePassword';
import LinkWithMicrosoft from '../LinkWithMicrosoft';
import Card from 'components/common/Card';

import useStyles from './styles';

const SecurityCard = () => {
  const classes = useStyles();

  const azureOauthToggle = useFeature(toggles.azureOauth);

  return (
    <Card
      header={lang.profile.security.securityHeader}
      className={classes.card}
    >
      <div className={classes.cardContent}>
        <ChangePassword />
        <Spacer px={30} />
        <Render when={azureOauthToggle}>
          <LinkWithMicrosoft />
        </Render>
      </div>
    </Card >
  );
};

export default SecurityCard;
