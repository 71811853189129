const countries = [
  {
    code: 'AF',
    name: {
      en: 'Afghanistan',
      pt: 'Afeganistão',
    },
  },
  {
    code: 'AX',
    name: {
      en: 'Aland Islands',
      pt: 'Ilhas Aland',
    },
  },
  {
    code: 'AL',
    name: {
      en: 'Albania',
      pt: 'Albânia',
    },
  },
  {
    code: 'DZ',
    name: {
      en: 'Algeria',
      pt: 'Argélia',
    },
  },
  {
    code: 'AS',
    name: {
      en: 'American Samoa',
      pt: 'Samoa Americana',
    },
  },
  {
    code: 'AD',
    name: {
      en: 'Andorra',
      pt: 'Andorra',
    },
  },
  {
    code: 'AO',
    name: {
      en: 'Angola',
      pt: 'Angola',
    },
  },
  {
    code: 'AI',
    name: {
      en: 'Anguilla',
      pt: 'Anguila',
    },
  },
  {
    code: 'AQ',
    name: {
      en: 'Antarctica',
      pt: 'Antártica',
    },
  },
  {
    code: 'AG',
    name: {
      en: 'Antigua and Barbuda',
      pt: 'Antígua e Barbuda',
    },
  },
  {
    code: 'AR',
    name: {
      en: 'Argentina',
      pt: 'Argentina',
    },
  },
  {
    code: 'AM',
    name: {
      en: 'Armenia',
      pt: 'Armênia',
    },
  },
  {
    code: 'AW',
    name: {
      en: 'Aruba',
      pt: 'Aruba',
    },
  },
  {
    code: 'AU',
    name: {
      en: 'Australia',
      pt: 'Austrália',
    },
  },
  {
    code: 'AT',
    name: {
      en: 'Austria',
      pt: 'Áustria',
    },
  },
  {
    code: 'AZ',
    name: {
      en: 'Azerbaijan',
      pt: 'Azerbaijão',
    },
  },
  {
    code: 'BS',
    name: {
      en: 'Bahamas',
      pt: 'Bahamas',
    },
  },
  {
    code: 'BH',
    name: {
      en: 'Bahrain',
      pt: 'Bahrein',
    },
  },
  {
    code: 'BD',
    name: {
      en: 'Bangladesh',
      pt: 'Bangladesh',
    },
  },
  {
    code: 'BB',
    name: {
      en: 'Barbados',
      pt: 'Barbados',
    },
  },
  {
    code: 'BY',
    name: {
      en: 'Belarus',
      pt: 'Bielorrússia',
    },
  },
  {
    code: 'BE',
    name: {
      en: 'Belgium',
      pt: 'Bélgica',
    },
  },
  {
    code: 'BZ',
    name: {
      en: 'Belize',
      pt: 'Belize',
    },
  },
  {
    code: 'BJ',
    name: {
      en: 'Benin',
      pt: 'Benim',
    },
  },
  {
    code: 'BM',
    name: {
      en: 'Bermuda',
      pt: 'Bermudas',
    },
  },
  {
    code: 'BT',
    name: {
      en: 'Bhutan',
      pt: 'Butão',
    },
  },
  {
    code: 'BO',
    name: {
      en: 'Bolivia',
      pt: 'Bolívia',
    },
  },
  {
    code: 'BA',
    name: {
      en: 'Bosnia and Herzegovina',
      pt: 'Bósnia e Herzegovina',
    },
  },
  {
    code: 'BW',
    name: {
      en: 'Botswana',
      pt: 'Botsuana',
    },
  },
  {
    code: 'BV',
    name: {
      en: 'Bouvet Island',
      pt: 'Ilha Bouvet',
    },
  },
  {
    code: 'BR',
    name: {
      en: 'Brazil',
      pt: 'Brasil',
    },
  },
  {
    code: 'IO',
    name: {
      en: 'British Indian Ocean Territory',
      pt: 'Território Britânico do Oceano Índico',
    },
  },
  {
    code: 'BN',
    name: {
      en: 'Brunei Darussalam',
      pt: 'Brunei Darussalam',
    },
  },
  {
    code: 'BG',
    name: {
      en: 'Bulgaria',
      pt: 'Bulgária',
    },
  },
  {
    code: 'BF',
    name: {
      en: 'Burkina Faso',
      pt: 'Burquina Faso',
    },
  },
  {
    code: 'BI',
    name: {
      en: 'Burundi',
      pt: 'Burundi',
    },
  },
  {
    code: 'KH',
    name: {
      en: 'Cambodia',
      pt: 'Camboja',
    },
  },
  {
    code: 'CM',
    name: {
      en: 'Cameroon',
      pt: 'Camarões',
    },
  },
  {
    code: 'CA',
    name: {
      en: 'Canada',
      pt: 'Canadá',
    },
  },
  {
    code: 'CV',
    name: {
      en: 'Cape Verde',
      pt: 'Cabo Verde',
    },
  },
  {
    code: 'KY',
    name: {
      en: 'Cayman Islands',
      pt: 'Ilhas Cayman',
    },
  },
  {
    code: 'CF',
    name: {
      en: 'Central African Republic',
      pt: 'República Centro-Africana',
    },
  },
  {
    code: 'TD',
    name: {
      en: 'Chad',
      pt: 'Chade',
    },
  },
  {
    code: 'CL',
    name: {
      en: 'Chile',
      pt: 'Chile',
    },
  },
  {
    code: 'CN',
    name: {
      en: 'China',
      pt: 'China',
    },
  },
  {
    code: 'CX',
    name: {
      en: 'Christmas Island',
      pt: 'Ilha Christmas',
    },
  },
  {
    code: 'CC',
    name: {
      en: 'Cocos (Keeling) Islands',
      pt: 'Ilhas Cocos (Keeling)',
    },
  },
  {
    code: 'CO',
    name: {
      en: 'Colombia',
      pt: 'Colômbia',
    },
  },
  {
    code: 'KM',
    name: {
      en: 'Comoros',
      pt: 'Comores',
    },
  },
  {
    code: 'CG',
    name: {
      en: 'Congo',
      pt: 'Congo',
    },
  },
  {
    code: 'CD',
    name: {
      en: 'Congo, the Democratic Republic of the',
      pt: 'República Democrática do Congo',
    },
  },
  {
    code: 'CK',
    name: {
      en: 'Cook Islands',
      pt: 'Ilhas Cook',
    },
  },
  {
    code: 'CR',
    name: {
      en: 'Costa Rica',
      pt: 'Costa Rica',
    },
  },
  {
    code: 'CI',
    name: {
      en: 'Côte d\'Ivoire',
      pt: 'Costa do Marfim',
    },
  },
  {
    code: 'HR',
    name: {
      en: 'Croatia',
      pt: 'Croácia',
    },
  },
  {
    code: 'CU',
    name: {
      en: 'Cuba',
      pt: 'Cuba',
    },
  },
  {
    code: 'CY',
    name: {
      en: 'Cyprus',
      pt: 'Chipre',
    },
  },
  {
    code: 'CZ',
    name: {
      en: 'Czech Republic',
      pt: 'República Checa',
    },
  },
  {
    code: 'DK',
    name: {
      en: 'Denmark',
      pt: 'Dinamarca',
    },
  },
  {
    code: 'DJ',
    name: {
      en: 'Djibouti',
      pt: 'Djibouti',
    },
  },
  {
    code: 'DM',
    name: {
      en: 'Dominica',
      pt: 'Dominica',
    },
  },
  {
    code: 'DO',
    name: {
      en: 'Dominican Republic',
      pt: 'República Dominicana',
    },
  },
  {
    code: 'EC',
    name: {
      en: 'Ecuador',
      pt: 'Equador',
    },
  },
  {
    code: 'EG',
    name: {
      en: 'Egypt',
      pt: 'Egito',
    },
  },
  {
    code: 'SV',
    name: {
      en: 'El Salvador',
      pt: 'El Salvador',
    },
  },
  {
    code: 'GQ',
    name: {
      en: 'Equatorial Guinea',
      pt: 'Guiné Equatorial',
    },
  },
  {
    code: 'ER',
    name: {
      en: 'Eritrea',
      pt: 'Eritreia',
    },
  },
  {
    code: 'EE',
    name: {
      en: 'Estonia',
      pt: 'Estónia',
    },
  },
  {
    code: 'ET',
    name: {
      en: 'Ethiopia',
      pt: 'Etiópia',
    },
  },
  {
    code: 'FK',
    name: {
      en: 'Falkland Islands (Malvinas)',
      pt: 'Ilhas Falkland (Malvinas)',
    },
  },
  {
    code: 'FO',
    name: {
      en: 'Faroe Islands',
      pt: 'Ilhas Faroé',
    },
  },
  {
    code: 'FJ',
    name: {
      en: 'Fiji',
      pt: 'Fiji',
    },
  },
  {
    code: 'FI',
    name: {
      en: 'Finland',
      pt: 'Finlândia',
    },
  },
  {
    code: 'FR',
    name: {
      en: 'France',
      pt: 'França',
    },
  },
  {
    code: 'GF',
    name: {
      en: 'French Guiana',
      pt: 'Guiana Francesa',
    },
  },
  {
    code: 'PF',
    name: {
      en: 'French Polynesia',
      pt: 'Polinésia Francesa',
    },
  },
  {
    code: 'TF',
    name: {
      en: 'French Southern Territories',
      pt: 'Territórios Franceses do Sul',
    },
  },
  {
    code: 'GA',
    name: {
      en: 'Gabon',
      pt: 'Gabão',
    },
  },
  {
    code: 'GM',
    name: {
      en: 'Gambia',
      pt: 'Gâmbia',
    },
  },
  {
    code: 'GE',
    name: {
      en: 'Georgia',
      pt: 'Geórgia',
    },
  },
  {
    code: 'DE',
    name: {
      en: 'Germany',
      pt: 'Alemanha',
    },
  },
  {
    code: 'GH',
    name: {
      en: 'Ghana',
      pt: 'Gana',
    },
  },
  {
    code: 'GI',
    name: {
      en: 'Gibraltar',
      pt: 'Gibraltar',
    },
  },
  {
    code: 'GR',
    name: {
      en: 'Greece',
      pt: 'Grécia',
    },
  },
  {
    code: 'GL',
    name: {
      en: 'Greenland',
      pt: 'Gronelândia',
    },
  },
  {
    code: 'GD',
    name: {
      en: 'Grenada',
      pt: 'Granada',
    },
  },
  {
    code: 'GP',
    name: {
      en: 'Guadeloupe',
      pt: 'Guadalupe',
    },
  },
  {
    code: 'GU',
    name: {
      en: 'Guam',
      pt: 'Guam',
    },
  },
  {
    code: 'GT',
    name: {
      en: 'Guatemala',
      pt: 'Guatemala',
    },
  },
  {
    code: 'GG',
    name: {
      en: 'Guernsey',
      pt: 'Guernsey',
    },
  },
  {
    code: 'GN',
    name: {
      en: 'Guinea',
      pt: 'Guiné',
    },
  },
  {
    code: 'GW',
    name: {
      en: 'Guinea-Bissau',
      pt: 'Guiné-Bissau',
    },
  },
  {
    code: 'GY',
    name: {
      en: 'Guyana',
      pt: 'Guiana',
    },
  },
  {
    code: 'HT',
    name: {
      en: 'Haiti',
      pt: 'Haiti',
    },
  },
  {
    code: 'HM',
    name: {
      en: 'Heard Island and McDonald Islands',
      pt: 'Ilha Heard e Ilhas McDonald',
    },
  },
  {
    code: 'VA',
    name: {
      en: 'Holy See (Vatican City State)',
      pt: 'Santa Sé (Estado da Cidade do Vaticano)',
    },
  },
  {
    code: 'HN',
    name: {
      en: 'Honduras',
      pt: 'Honduras',
    },
  },
  {
    code: 'HK',
    name: {
      en: 'Hong Kong',
      pt: 'Hong Kong',
    },
  },
  {
    code: 'HU',
    name: {
      en: 'Hungary',
      pt: 'Hungria',
    },
  },
  {
    code: 'IS',
    name: {
      en: 'Iceland',
      pt: 'Islândia',
    },
  },
  {
    code: 'IN',
    name: {
      en: 'India',
      pt: 'Índia',
    },
  },
  {
    code: 'ID',
    name: {
      en: 'Indonesia',
      pt: 'Indonésia',
    },
  },
  {
    code: 'IR',
    name: {
      en: 'Iran, Islamic Republic of',
      pt: 'Irão, República Islâmica do',
    },
  },
  {
    code: 'IQ',
    name: {
      en: 'Iraq',
      pt: 'Iraque',
    },
  },
  {
    code: 'IE',
    name: {
      en: 'Ireland',
      pt: 'Irlanda',
    },
  },
  {
    code: 'IM',
    name: {
      en: 'Isle of Man',
      pt: 'Ilha de Man',
    },
  },
  {
    code: 'IL',
    name: {
      en: 'Israel',
      pt: 'Israel',
    },
  },
  {
    code: 'IT',
    name: {
      en: 'Italy',
      pt: 'Itália',
    },
  },
  {
    code: 'JM',
    name: {
      en: 'Jamaica',
      pt: 'Jamaica',
    },
  },
  {
    code: 'JP',
    name: {
      en: 'Japan',
      pt: 'Japão',
    },
  },
  {
    code: 'JE',
    name: {
      en: 'Jersey',
      pt: 'Jersey',
    },
  },
  {
    code: 'JO',
    name: {
      en: 'Jordan',
      pt: 'Jordânia',
    },
  },
  {
    code: 'KZ',
    name: {
      en: 'Kazakhstan',
      pt: 'Cazaquistão',
    },
  },
  {
    code: 'KE',
    name: {
      en: 'Kenya',
      pt: 'Quénia',
    },
  },
  {
    code: 'KI',
    name: {
      en: 'Kiribati',
      pt: 'Kiribati',
    },
  },
  {
    code: 'KP',
    name:

   {
     en: 'Korea, Democratic People\'s Republic of',
     pt: 'Coreia, República Democrática Popular da',
   },
  },
  {
    code: 'KR',
    name: {
      en: 'Korea, Republic of',
      pt: 'Coreia, República da',
    },
  },
  {
    code: 'KW',
    name: {
      en: 'Kuwait',
      pt: 'Kuwait',
    },
  },
  {
    code: 'KG',
    name: {
      en: 'Kyrgyzstan',
      pt: 'Quirguistão',
    },
  },
  {
    code: 'LA',
    name: {
      en: 'Lao People\'s Democratic Republic',
      pt: 'Laos, República Democrática Popular do',
    },
  },
  {
    code: 'LV',
    name: {
      en: 'Latvia',
      pt: 'Letónia',
    },
  },
  {
    code: 'LB',
    name: {
      en: 'Lebanon',
      pt: 'Líbano',
    },
  },
  {
    code: 'LS',
    name: {
      en: 'Lesotho',
      pt: 'Lesoto',
    },
  },
  {
    code: 'LR',
    name: {
      en: 'Liberia',
      pt: 'Libéria',
    },
  },
  {
    code: 'LY',
    name: {
      en: 'Libya',
      pt: 'Líbia',
    },
  },
  {
    code: 'LI',
    name: {
      en: 'Liechtenstein',
      pt: 'Listenstaine',
    },
  },
  {
    code: 'LT',
    name: {
      en: 'Lithuania',
      pt: 'Lituânia',
    },
  },
  {
    code: 'LU',
    name: {
      en: 'Luxembourg',
      pt: 'Luxemburgo',
    },
  },
  {
    code: 'MO',
    name: {
      en: 'Macao',
      pt: 'Macau',
    },
  },
  {
    code: 'MG',
    name: {
      en: 'Madagascar',
      pt: 'Madagáscar',
    },
  },
  {
    code: 'MW',
    name: {
      en: 'Malawi',
      pt: 'Maláui',
    },
  },
  {
    code: 'MY',
    name: {
      en: 'Malaysia',
      pt: 'Malásia',
    },
  },
  {
    code: 'MV',
    name: {
      en: 'Maldives',
      pt: 'Maldivas',
    },
  },
  {
    code: 'ML',
    name: {
      en: 'Mali',
      pt: 'Mali',
    },
  },
  {
    code: 'MT',
    name: {
      en: 'Malta',
      pt: 'Malta',
    },
  },
  {
    code: 'MH',
    name: {
      en: 'Marshall Islands',
      pt: 'Ilhas Marshall',
    },
  },
  {
    code: 'MQ',
    name: {
      en: 'Martinique',
      pt: 'Martinica',
    },
  },
  {
    code: 'MR',
    name: {
      en: 'Mauritania',
      pt: 'Mauritânia',
    },
  },
  {
    code: 'MU',
    name: {
      en: 'Mauritius',
      pt: 'Maurícia',
    },
  },
  {
    code: 'YT',
    name: {
      en: 'Mayotte',
      pt: 'Maiote',
    },
  },
  {
    code: 'MX',
    name: {
      en: 'Mexico',
      pt: 'México',
    },
  },
  {
    code: 'FM',
    name: {
      en: 'Micronesia, Federated States of',
      pt: 'Micronésia, Estados Federados da',
    },
  },
  {
    code: 'MD',
    name: {
      en: 'Moldova, Republic of',
      pt: 'Moldávia, República da',
    },
  },
  {
    code: 'MC',
    name: {
      en: 'Monaco',
      pt: 'Mónaco',
    },
  },
  {
    code: 'MN',
    name: {
      en: 'Mongolia',
      pt: 'Mongólia',
    },
  },
  {
    code: 'ME',
    name: {
      en: 'Montenegro',
      pt: 'Montenegro',
    },
  },
  {
    code: 'MS',
    name: {
      en: 'Montserrat',
      pt: 'Monserrate',
    },
  },
  {
    code: 'MA',
    name: {
      en: 'Morocco',
      pt: 'Marrocos',
    },
  },
  {
    code: 'MZ',
    name: {
      en: 'Mozambique',
      pt: 'Moçambique',
    },
  },
  {
    code: 'MM',
    name: {
      en: 'Myanmar',
      pt: 'Myanmar',
    },
  },
  {
    code: 'NA',
    name: {
      en: 'Namibia',
      pt: 'Namíbia',
    },
  },
  {
    code: 'NR',
    name: {
      en: 'Nauru',
      pt: 'Nauru',
    },
  },
  {
    code: 'NP',
    name: {
      en: 'Nepal',
      pt: 'Nepal',
    },
  },
  {
    code: 'NL',
    name: {
      en: 'Netherlands',
      pt: 'Países Baixos',
    },
  },
  {
    code: 'NC',
    name: {
      en: 'New Caledonia',
      pt: 'Nova Caledónia',
    },
  },
  {
    code: 'NZ',
    name: {
      en: 'New Zealand',
      pt: 'Nova Zelândia',
    },
  },
  {
    code: 'NI',
    name: {
      en: 'Nicaragua',
      pt: 'Nicarágua',
    },
  },
  {
    code: 'NE',
    name: {
      en: 'Niger',
      pt: 'Níger',
    },
  },
  {
    code: 'NG',
    name: {
      en: 'Nigeria',
      pt: 'Nigéria',
    },
  },
  {
    code: 'NU',
    name: {
      en: 'Niue',
      pt: 'Niue',
    },
  },
  {
    code: 'NF',
    name: {
      en: 'Norfolk Island',
      pt: 'Ilha Norfolk',
    },
  },
  {
    code: 'MP',
    name: {
      en: 'Northern Mariana Islands',
      pt: 'Ilhas Marianas do Norte',
    },
  },
  {
    code: 'NO',
    name: {
      en: 'Norway',
      pt: 'Noruega',
    },
  },
  {
    code: 'OM',
    name: {
      en: 'Oman',
      pt: 'Omã',
    },
  },
  {
    code: 'PK',
    name: {
      en: 'Pakistan',
      pt: 'Paquistão',
    },
  },
  {
    code: 'PW',
    name: {
      en: 'Palau',
      pt: 'Palau',
    },
  },
  {
    code: 'PS',
    name: {
      en: 'Palestine, State of',
      pt: 'Palestina, Estado da',
    },
  },
  {
    code: 'PA',
    name: {
      en: 'Panama',
      pt: 'Panamá',
    },
  },
  {
    code: 'PG',
    name: {
      en: 'Papua New Guinea',
      pt: 'Papua-Nova Guiné',
    },
  },
  {
    code: 'PY',
    name: {
      en: 'Paraguay',
      pt: 'Paraguai',
    },
  },
  {
    code: 'PE',
    name: {
      en: 'Peru',
      pt: 'Peru',
    },
  },
  {
    code: 'PH',
    name: {
      en: 'Philippines',
      pt: 'Filipinas',
    },
  },
  {
    code: 'PN',
    name: {
      en: 'Pitcairn',
      pt: 'Pitcairn',
    },
  },
  {
    code: 'PL',
    name: {
      en: 'Poland',
      pt: 'Polónia',
    },
  },
  {
    code: 'PT',
    lang: 'pt',
    name: {
      en: 'Portugal',
      pt: 'Portugal',
    },
  },
  {
    code: 'PR',
    name: {
      en: 'Puerto Rico',
      pt: 'Porto Rico',
    },
  },
  {
    code: 'QA',
    name: {
      en: 'Qatar',
      pt: 'Catar',
    },
  },
  {
    code: 'RE',
    name: {
      en: 'Réunion',
      pt: 'Reunião',
    },
  },
  {
    code: 'RO',
    name: {
      en: 'Romania',
      pt: 'Roménia',
    },
  },
  {
    code: 'RU',
    name: {
      en: 'Russian Federation',
      pt: 'Federação Russa',
    },
  },
  {
    code: 'RW',
    name: {
      en: 'Rwanda',
      pt: 'Ruanda',
    },
  },
  {
    code: 'BL',
    name: {
      en: 'Saint Barthélemy',
      pt: 'São Bartolomeu',
    },
  },
  {
    code: 'SH',
    name: {
      en: 'Saint Helena, Ascension and Tristan da Cunha',
      pt: 'Santa Helena, Ascensão e Tristão da Cunha',
    },
  },
  {
    code: 'KN',
    name: {
      en: 'Saint Kitts and Nevis',
      pt: 'São Cristóvão e Nevis',
    },
  },
  {
    code: 'LC',
    name: {
      en: 'Saint Lucia',
      pt: 'Santa Lúcia',
    },
  },
  {
    code: 'MF',
    name: {
      en: 'Saint Martin (French part)',
      pt: 'São Martinho (parte francesa)',
    },
  },
  {
    code: 'PM',
    name: {
      en: 'Saint Pierre and Miquelon',
      pt: 'São Pedro e Miquelon',
    },
  },
  {
    code: 'VC',
    name: {
      en: 'Saint Vincent and the Grenadines',
      pt: 'São Vicente e Granadinas',
    },
  },
  {
    code: 'WS',
    name: {
      en: 'Samoa',
      pt: 'Samoa',
    },
  },
  {
    code: 'SM',
    name: {
      en: 'San Marino',
      pt: 'San Marino',
    },
  },
  {
    code: 'ST',
    name: {
      en: 'Sao Tome and Principe',
      pt: 'São Tomé e Príncipe',
    },
  },
  {
    code: 'SA',
    name: {
      en: 'Saudi Arabia',
      pt: 'Arábia Saudita',
    },
  },
  {
    code: 'SN',
    name: {
      en: 'Senegal',
      pt: 'Senegal',
    },
  },
  {
    code: 'RS',
    name: {
      en: 'Serbia',
      pt: 'Sérvia',
    },
  },
  {
    code: 'SC',
    name: {
      en: 'Seychelles',
      pt: 'Seicheles',
    },
  },
  {
    code: 'SL',
    name: {
      en: 'Sierra Leone',
      pt: 'Serra Leoa',
    },
  },
  {
    code: 'SG',
    name: {
      en: 'Singapore',
      pt: 'Cingapura',
    },
  },
  {
    code: 'SX',
    name: {
      en: 'Sint Maarten (Dutch part)',
      pt: 'Sint Maarten (parte holandesa)',
    },
  },
  {
    code: 'SK',
    name: {
      en: 'Slovakia',
      pt: 'Eslováquia',
    },
  },
  {
    code: 'SI',
    name: {
      en: 'Slovenia',
      pt: 'Eslovénia',
    },
  },
  {
    code: 'SB',
    name: {
      en: 'Solomon Islands',
      pt: 'Ilhas Salomão',
    },
  },
  {
    code: 'SO',
    name: {
      en: 'Somalia',
      pt: 'Somália',
    },
  },
  {
    code: 'ZA',
    name: {
      en: 'South Africa',
      pt: 'África do Sul',
    },
  },
  {
    code: 'GS',
    name: {
      en: 'South Georgia and the South Sandwich Islands',
      pt: 'Geórgia do Sul e Ilhas Sandwich do Sul',
    },
  },
  {
    code: 'SS',
    name: {
      en: 'South Sudan',
      pt: 'Sudão do Sul',
    },
  },
  {
    code: 'ES',
    name: {
      en: 'Spain',
      pt: 'Espanha',
    },
  },
  {
    code: 'LK',
    name: {
      en: 'Sri Lanka',
      pt: 'Sri Lanka',
    },
  },
  {
    code: 'SD',
    name: {
      en: 'Sudan',
      pt: 'Sudão',
    },
  },
  {
    code: 'SR',
    name: {
      en: 'Suriname',
      pt: 'Suriname',
    },
  },
  {
    code: 'SJ',
    name: {
      en: 'Svalbard and Jan Mayen',
      pt: 'Svalbard e Jan Mayen',
    },
  },
  {
    code: 'SZ',
    name: {
      en: 'Swaziland',
      pt: 'Suazilândia',
    },
  },
  {
    code: 'SE',
    name: {
      en: 'Sweden',
      pt: 'Suécia',
    },
  },
  {
    code: 'CH',
    name: {
      en: 'Switzerland',
      pt: 'Suíça',
    },
  },
  {
    code: 'SY',
    name: {
      en: 'Syrian Arab Republic',
      pt: 'República Árabe Síria',
    },
  },
  {
    code: 'TW',
    name: {
      en: 'Taiwan, Province of China',
      pt: 'Taiwan, Província da China',
    },
  },
  {
    code: 'TJ',
    name: {
      en: 'Tajikistan',
      pt: 'Tajiquistão',
    },
  },
  {
    code: 'TZ',
    name: {
      en: 'Tanzania, United Republic of',
      pt: 'Tanzânia, República Unida da',
    },
  },
  {
    code: 'TH',
    name: {
      en: 'Thailand',
      pt: 'Tailândia',
    },
  },
  {
    code: 'TG',
    name: {
      en: 'Togo',
      pt: 'Togo',
    },
  },
  {
    code: 'TK',
    name: {
      en: 'Tokelau',
      pt: 'Tokelau',
    },
  },
  {
    code: 'TO',
    name: {
      en: 'Tonga',
      pt: 'Tonga',
    },
  },
  {
    code: 'TT',
    name: {
      en: 'Trinidad and Tobago',
      pt: 'Trinidad e Tobago',
    },
  },
  {
    code: 'TN',
    name: {
      en: 'Tunisia',
      pt: 'Tunísia',
    },
  },
  {
    code: 'TR',
    name: {
      en: 'Turkey',
      pt: 'Turquia',
    },
  },
  {
    code: 'TM',
    name: {
      en: 'Turkmenistan',
      pt: 'Turcomenistão',
    },
  },
  {
    code: 'TC',
    name: {
      en: 'Turks and Caicos Islands',
      pt: 'Ilhas Turcas e Caicos',
    },
  },
  {
    code: 'TV',
    name: {
      en: 'Tuvalu',
      pt: 'Tuvalu',
    },
  },
  {
    code: 'UG',
    name: {
      en: 'Uganda',
      pt: 'Uganda',
    },
  },
  {
    code: 'UA',
    name: {
      en: 'Ukraine',
      pt: 'Ucrânia',
    },
  },
  {
    code: 'AE',
    name: {
      en: 'United Arab Emirates',
      pt: 'Emirados Árabes Unidos',
    },
  },
  {
    code: 'GB',
    lang: 'en',
    name: {
      en: 'United Kingdom',
      pt: 'Reino Unido',
    },
  },
  {
    code: 'US',
    name: {
      en: 'United States of America',
      pt: 'Estados Unidos da América',
    },
  },
  {
    code: 'UY',
    name: {
      en: 'Uruguay',
      pt: 'Uruguai',
    },
  },
  {
    code: 'UZ',
    name: {
      en: 'Uzbekistan',
      pt: 'Uzbequistão',
    },
  },
  {
    code: 'VU',
    name: {
      en: 'Vanuatu',
      pt: 'Vanuatu',
    },
  },
  {
    code: 'VE',
    name: {
      en: 'Venezuela, Bolivarian Republic of',
      pt: 'Venezuela, República Bolivariana da',
    },
  },
  {
    code: 'VN',
    name: {
      en: 'Viet Nam',
      pt: 'Vietname',
    },
  },
  {
    code: 'WF',
    name: {
      en: 'Wallis and Futuna',
      pt: 'Wallis e Futuna',
    },
  },
  {
    code: 'EH',
    name: {
      en: 'Western Sahara',
      pt: 'Saara Ocidental',
    },
  },
  {
    code: 'YE',
    name: {
      en: 'Yemen',
      pt: 'Iémen',
    },
  },
  {
    code: 'ZM',
    name: {
      en: 'Zambia',
      pt: 'Zâmbia',
    },
  },
  {
    code: 'ZW',
    name: {
      en: 'Zimbabwe',
      pt: 'Zimbábue',
    },
  },
];

export default countries;

