import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  pageContainer: {
    minHeight: '100vh',
    display: 'flex',
  },
  langSelectorContainer: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    zIndex: '1000',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    backgroundColor: theme.midnightGreen,
    width: '50%',
    '&.iave': {
      backgroundColor: '#0082D7',
    },
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    position: 'relative',
  },
  card: {
    width: '100%',
    margin: '20px 20%',
    padding: '30px',
    boxShadow: `0 0 5px ${theme.shadowColor}`,
    backgroundColor: theme.backgroundColor,
    borderRadius: '18px',
  },
  loginHeader: {
    paddingTop: '10px',
    marginBottom: '24px',
    fontSize: '24px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    pageContainer: {
      flexDirection: 'column',
      backgroundColor: theme.midnightGreen,
      '&.iave': {
        backgroundColor: '#0082D7',
      },
    },
    logoContainer: {
      alignItems: 'start',
      width: '100%',
      paddingTop: '7vh',
    },
    contentContainer: {
      width: '100%',
      flexWrap: 'wrap',
    },
    card: {
      margin: '20px 10%',
    },
  },
}));
