import React from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useSelector } from 'react-redux';

import { selectHasPersonalSpace, selectUserSchools } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import lang from 'lang';
import routes from 'routes';

import Button from 'components/common/Button';
import Logo from 'components/common/Logo';

import useStyles from './styles';

const Limbo = () => {
  const classes = useStyles();
  const [editPreferencesRequest, loading] = useApi(api.editPreferences);
  const toast = useToast();
  const hasPersonalSpace = useSelector(selectHasPersonalSpace);
  const userSchools = useSelector(selectUserSchools);

  const addPersonalSpace = () => {
    editPreferencesRequest([], { hasPersonalSpace: true }, ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          window.location = routes.tests.ref();
        }, 200);
        return;
      }

      setTimeout(() => {
        toast.error(lang.oops);
      }, 200);
    });
  };

  if (hasPersonalSpace || userSchools.length !== 0) {
    window.location = routes.tests.ref();
    return false;
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.logoWrapper}>
        <Logo dark medium />
      </div>
      <div className={classes.cardWrapper}>
        <div className={classes.card}>
          <div className={classes.title}>
            {lang.limbo.title}
          </div>
          <div className={classes.message}>
            {lang.limbo.description}
          </div>
          <div className={classes.buttonContainer}>
            <Button
              onClick={addPersonalSpace}
              loading={loading}
            >
              {lang.limbo.addPersonalSpace}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Limbo;
