import React, { useCallback, useState } from 'react';
import { Spacer, Toggle } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';

import { selectHasPersonalSpace, selectUserCurriculum, setPersonalSpace, setPreferences } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import useFeature from 'hooks/useFeature';
import usePreferences from 'hooks/usePreferences';
import usePreferencesOptions from 'hooks/usePreferencesOptions';
import lang from 'lang';
import toggles from 'toggles';

import CountrySelector from '../CountrySelector';
import SubjectsSelector from '../SubjectsSelector';
import YearsSelector from '../YearsSelector';
import Button from 'components/common/Button';
import Card from 'components/common/Card';

import useStyles from './styles';

const ProfilePreferencesCard = () => {
  const classes = useStyles();
  const toast = useToast();
  const dispatch = useDispatch();
  const [editPreferencesRequest, loading] = useApi(api.editPreferences);
  const userHasPersonalSpace = useSelector(selectHasPersonalSpace);
  const exploreCurriculum = useFeature(toggles.exploreCurriculum);
  const userCurriculum = useSelector(selectUserCurriculum);

  const [newPreferences, setNewPreferences] = usePreferences();
  const [subjects, years] = usePreferencesOptions(userCurriculum.id);
  const [hasPersonalSpace, setHasPersonalSpace] = useState(userHasPersonalSpace);

  const togglePersonalSpace = useCallback((value) => {
    setHasPersonalSpace(value);
  }, []);

  const editPreferences = () => {
    editPreferencesRequest([], { ...newPreferences, hasPersonalSpace, curriculum: newPreferences.curriculum?.id }, ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          toast.success(lang.changesSaved);
          dispatch(setPreferences(newPreferences));
          dispatch(setPersonalSpace(hasPersonalSpace));
        }, 200);

      } else {
        setTimeout(() => {
          toast.error(lang.oops);
        }, 200);
      }
    });
  };

  return (
    <Card
      header={lang.profile.profilePreferences.profilePreferences}
      className={classes.card}
    >
      <div className={classes.cardContent}>
        <div className={classes.areas}>
          <div className={classes.label}>
            {lang.profile.profilePreferences.personalSpace}
          </div>
          <div className={classes.labelDescription}>
            {lang.profile.profilePreferences.personalSpaceDescription}
          </div>
          <div className={classes.preferencesToggle}>
            <Toggle
              checked={hasPersonalSpace}
              onChange={togglePersonalSpace}
            />
            {lang.profile.profilePreferences.personalSpaceCheckboxLabel}
          </div>
        </div>
        {exploreCurriculum &&
          <div>
            <Spacer px={20} />
            <CountrySelector
              newPreferences={newPreferences}
              setNewPreferences={setNewPreferences}
            />
          </div>
        }
        <Spacer px={20} />
        <SubjectsSelector
          newPreferences={newPreferences}
          setNewPreferences={setNewPreferences}
          subjects={subjects}
        />
        <Spacer px={20} />
        <YearsSelector
          newPreferences={newPreferences}
          setNewPreferences={setNewPreferences}
          years={years}
        />
        <Spacer px={20} />
        <div>
          <Button
            onClick={editPreferences}
            loading={loading}
          >
            {lang.saveChanges}
          </Button>
        </div>
      </div>
    </Card >
  );
};

export default ProfilePreferencesCard;
