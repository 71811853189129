import React from 'react';
import { Spacer } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useSelector, useDispatch } from 'react-redux';
import isEmail from 'validator/lib/isEmail';

import { selectUserData, setInformation } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import useInput from 'hooks/useInput';
import lang from 'lang';

import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Input from 'components/common/input/Input';

import useStyles from './styles';

const ProfileInformationCard = () => {
  const classes = useStyles();
  const { fullName, email } = useSelector(selectUserData);
  const toast = useToast();
  const dispatch = useDispatch();
  const [editInformationRequest, loading] = useApi(api.editInformation);

  const [newName, setNewName, newNameErrors, setNewNameErrors] = useInput(fullName);
  const [newEmail, setNewEmail, newEmailErrors, setNewEmailErrors] = useInput(email);

  const save = (event) => {
    event.preventDefault();

    let valid = true;
    const nameErrors = [];
    const emailErrors = [];

    if (!newName) {
      nameErrors.push([lang.profile.nameRequiredError]);
      valid = false;
    }

    if (!newEmail) {
      emailErrors.push([lang.profile.emailRequiredError]);
      valid = false;
    }

    if (!isEmail(newEmail)) {
      emailErrors.push([lang.profile.emailInvalidError]);
      valid = false;
    }

    setNewNameErrors(nameErrors);
    setNewEmailErrors(emailErrors);
    if (!valid) {
      return;
    }

    editInformationRequest([], { newName, newEmail }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          toast.success(lang.profile.profileInformation.informationUpdateSuccess);
          dispatch(setInformation({
            fullName: newName,
            email: newEmail,
          }));
          return;
        } else if (data.status === 2) {
          setNewEmailErrors([lang.profile.security.emailAlreadyInUse]);
          return;
        }

        toast.error(lang.oops);
      }, 300);
    });
  };

  return (
    <Card
      header={lang.info}
      className={classes.card}
    >
      <div className={classes.infoCard}>
        <Input
          type="text"
          label={lang.profile.fullName}
          placeholder={lang.profile.fullName}
          value={newName}
          onChange={(event) => setNewName(event.target.value)}
          errors={newNameErrors}
        />
        <Spacer px={15} />
        <Input
          type="email"
          label={lang.profile.email}
          placeholder={lang.profile.email}
          value={newEmail}
          onChange={(event) => setNewEmail(event.target.value)}
          errors={newEmailErrors}
        />
        <Spacer px={20} />
        <div>
          <Button
            onClick={save}
            loading={loading}
          >
            {lang.saveChanges}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ProfileInformationCard;
