import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  name: {
    fontWeight: 600,
  },
  priceWrapper: {
    display: 'flex',
    gap: '5px',
    alignItems: 'flex-end',
  },
  price: {
    fontSize: '25px',
  },
  extraInfo: {
    textAlign: 'center',
  },
  dueDate: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mobileOption: {
    display: 'none',
  },
  card: {
    borderRadius: '18px',
    padding: '1em 1.5em',
    height: '100%',
    color: theme.midnightGreen,
    '&.selected': {
      border: `2px solid ${theme.midnightGreen}99`,
    },
    '&.free': {
      backgroundColor: `${theme.grey}0D`,
      marginTop: '28px',
    },
    '&.premium': {
      backgroundColor: `${theme.green}21`,
    },
    '&.schools': {
      backgroundColor: `${theme.airBlue}1A`,
      marginTop: '28px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1em',
    marginBottom: '15px',
  },
  planTarget: {
    color: 'grey',
    fontSize: '14px',
    marginRight: 'auto',
  },
  planPrice: {
    fontSize: '24px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'baseline',
    gap: '0.25em',
    marginBottom: '10px',
  },
  planDescription: {
    fontSize: '14px',
    marginBottom: '15px',
  },
  perMonth: {
    fontSize: '12px',
    fontWeight: '400',
  },
  planType: {
    borderRadius: '9px',
    padding: '0.5em 1.5em',
    fontSize: '12px',
    fontWeight: 600,
    '&.free': {
      backgroundColor: 'grey',
      color: 'white',
    },
    '&.premium': {
      backgroundColor: theme.midnightGreen,
      color: theme.green,
    },
    '&.schools': {
      backgroundColor: theme.green,
      color: theme.midnightGreen,
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
  },
  button: {
    marginBottom: '25px',
    backgroundColor: theme.midnightGreen,
    color: theme.green,
    padding: '0.5em 1.5em',
    display: 'flex',
    gap: '1em',
    width: 'fit-content',
    '&.schools': {
      backgroundColor: theme.green,
      color: theme.midnightGreen,
    },
  },
  cancelButton: {
    marginBottom: '25px',
    backgroundColor: theme.green,
    color: theme.midnightGreen,
    padding: '0.5em 1.5em',
    display: 'flex',
    gap: '1em',
    width: 'fit-content',
    '&.cancel': {
      backgroundColor: theme.errorColor,
      color: '#FFF',
    },
  },
  schoolLabel: {
    fontSize: '10px',
    color: 'grey',
  },
  featuresWrapper: {
    display: 'grid',
    gridTemplateColumns: '10% 90%',
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
    fontSize: '14px',
  },
  icon: {
    color: theme.green,
  },
  pricesWrapper: {
    display: 'flex',
    gap: '0.25em',
  },
  originalPrice: {
    textDecoration: 'line-through',
  },
  discountedPrice: {
    color: theme.orangeSoda,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.25em',
    placeSelf: 'stretch',
  },
  compactedWrapper: {
    marginRight: '10px',
    display: 'flex',
    gap: '0.25em',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: '14px',
  },
  toggle: {
    backgroundColor: `${theme.midnightGreen}F2`,
    '&:hover': {
      backgroundColor: theme.midnightGreen,
    },
  },
  yearlyExtraText: {
    opacity: '80%',
    marginBottom: '1em',
    fontSize: '14px',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    wrapper: {
      display: 'none',
    },
    mobileOption: {
      display: 'unset',
    },
  },
}));
