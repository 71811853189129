import { useCallback, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { useToast } from '@intuitivo-pt/outline-ui';
import axios from 'axios';
import { useHistory } from 'react-router';

import { loginRequest } from 'authConfig';
import { AZURE_SID, LOGIN_STRATEGY, SESSION_NONCE } from 'constants/cookies';
import { LOCAL } from 'constants/environments';
import { LOCAL_STRATEGY, MICROSOFT_STRATEGY } from 'constants/loginStrategies';
import { DELETE, GET, PATCH, POST, PUT } from 'constants/requestMethods';
import lang from 'lang';
import routes from 'routes';
import { getCookie } from 'utils';

const useApi = (endpoint, skipAuth) => {
  const [loading, setLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const toast = useToast();
  const history = useHistory();

  const getToken = useCallback(() => {
    return new Promise((res, rej) => {
      const loginStrategy = getCookie(LOGIN_STRATEGY);

      if (loginStrategy === LOCAL_STRATEGY) {
        const token = getCookie(SESSION_NONCE);
        return res(token);
      } else if (loginStrategy === MICROSOFT_STRATEGY) {
        const sid = getCookie(AZURE_SID);

        instance.acquireTokenSilent({
          account: account,
          scopes: loginRequest.scopes,
        }).then((token) => {
          return res(token.idToken);
        }).catch(() => {
          instance.ssoSilent({
            sid: sid,
            ...loginRequest,
          }).catch(() => {
            instance.loginRedirect({
              sid: sid,
              ...loginRequest,
            });
          });
        });
        return;
      }

      return rej(null);
    });
  }, [account, instance]);

  const callApi = useCallback((api, path, method, body, headers, callback) => {
    const resHandler = (res) => {
      if (res.data.status === -1) {
        history.push(routes.home.ref());
      }

      callback(res);
      setLoading(false);
    };

    const errorHandler = (error) => {
      if (error.response.status === 401) {
        history.push(routes.home.ref());
      }

      if (error.response.status !== 401) {
        toast.error(lang.internetWentWrong);
      }

      callback({ data: { status: 'error' } });
      setLoading(false);
    };

    let finalApi = api;
    if (process.env.REACT_APP_NODE_ENV === LOCAL) {
      const hostname = window.location.hostname;
      finalApi = api.replace('localhost', hostname);
    }

    setLoading(true);
    if (method === GET) {
      axios.get(finalApi + path, { headers }).then(resHandler).catch(errorHandler);
    } else if (method === POST) {
      axios.post(finalApi + path, body, { headers }).then(resHandler).catch(errorHandler);
    } else if (method === PUT) {
      axios.put(finalApi + path, body, { headers }).then(resHandler).catch(errorHandler);
    } else if (method === PATCH) {
      axios.patch(finalApi + path, body, { headers }).then(resHandler).catch(errorHandler);
    } else if (method === DELETE) {
      axios.delete(finalApi + path, { headers }).then(resHandler).catch(errorHandler);
    }
  }, [toast, history]);

  const request = useCallback((params, data, callback) => {
    const path = endpoint.path(...params);

    const headers = {};
    let body;

    if (endpoint.contentType === 'multipart/form-data') {
      headers['Content-Type'] = endpoint.contentType;

      const formData = new FormData();

      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });

      body = formData;
    } else {
      body = data;
    }

    if (!skipAuth) {
      getToken().then((token) => {
        headers.Authorization = 'Bearer ' + token;

        callApi(endpoint.apiUrl, path, endpoint.method, body, headers, callback);
      }).catch(() => {
        callback({ data: { status: 'error' } });
      });
      return;
    }

    callApi(endpoint.apiUrl, path, endpoint.method, body, headers, callback);
  }, [getToken, callApi, endpoint, skipAuth]);

  return [request, loading];
};

export default useApi;
