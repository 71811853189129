import React, { useEffect, useState } from 'react';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';

import { selectHasPersonalSpace, selectPaymentMethod, selectUserPlan, selectUserSchools, setPaymentMethod, selectUserData } from 'actions/userActions';
import api from 'api';
import { FREE, PREMIUM } from 'constants/plans';
import useApi from 'hooks/useApi';
import useFeature from 'hooks/useFeature';
import useQuery from 'hooks/useQuery';
import lang from 'lang';
import toggles from 'toggles';

import PaymentMethodsModal from '../PaymentMethodsModal';
import PlanCard from '../PlanCard';
import PlansCardModal from '../PlansCardModal';
import Button from 'components/common/Button';
import Card from 'components/common/Card';

import useStyles from './styles';

const ProfilePlansCard = () => {
  const classes = useStyles();
  const paymentMethod = useSelector(selectPaymentMethod);
  const userPlan = useSelector(selectUserPlan);
  const hasPersonalSpace = useSelector(selectHasPersonalSpace);
  const userSchools = useSelector(selectUserSchools);
  const query = useQuery();
  const toast = useToast();
  const [getPaymentMethodRequest] = useApi(api.getPaymentMethod);
  const dispatch = useDispatch();
  const customerId = (useSelector(selectUserData)).stripeId;
  const paymentMethodToggle = useFeature(toggles.paymentMethod);
  const [paymentMethodModal, setPaymentMethodModal] = useState(false);
  const [paymentMethodButtonLoading, setPaymentMethodButtonLoading] = useState(false);

  useEffect(() => {
    if (query().get('plan') && query().get('plan') === userPlan.name) {
      toast.success(lang.profile.plans.alreadyOnThatPlan);
    }

    if (paymentMethodToggle) {
      setPaymentMethodButtonLoading(true);
      getPaymentMethodRequest([], null, ({ data }) => {
        if (data.status === 0) {
          dispatch(setPaymentMethod(data.paymentMethod));
        }
        setPaymentMethodButtonLoading(false);
      });
    }
  }, [query, toast, userPlan, dispatch, getPaymentMethodRequest, paymentMethodToggle]);

  const getInitialState = () => {
    const paramsPlan = query().get('plan');
    if (!paramsPlan || (paramsPlan && paramsPlan === userPlan.name)) {
      return false;
    } else {
      return true;
    }
  };

  const [editPlanModal, setEditPlanModal] = useState(getInitialState);

  return (
    <Card
      header={lang.profile.plans.plan}
      className={classes.card}
    >
      <PlansCardModal
        open={editPlanModal}
        close={() => setEditPlanModal(false)}
      />
      <PaymentMethodsModal
        open={paymentMethodModal}
        close={() => setPaymentMethodModal(false)}
      />
      <div className={classes.cardSelectorWrapper}>
        <Render when={hasPersonalSpace}>
          <PlanCard plan={{ ...userPlan, isProfileCard: true }} />
        </Render>
        <Render when={userSchools.length}>
          <PlanCard plan={{ name: 'schools', isProfileCard: true }} />
        </Render>
        <div className={classes.buttonWrapper}>
          <Render when={(userPlan.name === PREMIUM && userPlan.price) || userPlan.name === FREE}>
            <Button
              onClick={() => setEditPlanModal(true)}
              sibling
            >
              {lang.profile.plans.switchPlan}
            </Button>
          </Render>
          <Render when={paymentMethodToggle && (paymentMethod || (userPlan.name === PREMIUM && !!userPlan.price) || (userPlan.name === FREE && customerId))}>
            <Button
              onClick={() => setPaymentMethodModal(true)}
              sibling
              loading={paymentMethodButtonLoading}
            >
              {paymentMethod ? lang.profile.plans.seePaymentMethod : lang.profile.plans.addPaymentMethod}
            </Button>
          </Render>
        </div>
      </div>
    </Card>
  );
};

export default ProfilePlansCard;
