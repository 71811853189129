import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import lang from 'lang';

import Tooltip from 'components/common/Tooltip/index';

import useStyles from './styles';

const PlansPill = ({ tip, message, getPremium, isFree, isSchool }) => {
  const classes = useStyles();

  return (
    <Tooltip tip={tip}>
      <div className={cx(classes.premiumPill, { getPremium: getPremium, isFree: isFree, isSchool: isSchool })}>
        {message ?? lang.profile.plans.premium}
      </div>
    </Tooltip>
  );
};

PlansPill.propTypes = {
  tip: PropTypes.string,
  message: PropTypes.string,
  isFree: PropTypes.bool,
  isSchool: PropTypes.bool,
  getPremium: PropTypes.bool,
};

export default PlansPill;
