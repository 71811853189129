import { APP_LOADED, TOGGLE_DARK, SHOW_LAYOUT } from 'actions/pageActions';

const initialState = {
  appLoading: true,
  toastVisible: false,
  toastMsg: '',
  isDark: false,
  layout: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOADED:
      return {
        ...state,
        appLoading: false,
      };
    case TOGGLE_DARK:
      return {
        ...state,
        isDark: !state.isDark,
      };
    case SHOW_LAYOUT:
      return {
        ...state,
        layout: action.layout,
      };
    default:
      return state;
  }
};

export default reducer;
