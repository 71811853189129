export default theme => ({
  aLink: {
    color: theme.textColor,
    textDecoration: 'none',
  },
  icon: {
    color: theme.primaryColor,
  },
  linkChildren: {
    color: theme.textColor2,
    display: 'inline-block',
    '&:hover': {
      color: theme.textColor,
      textDecoration: 'underline',
    },
    '&.icon': {
      marginLeft: '10px',
    },
  },
});
