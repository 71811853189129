import React, { useEffect, useState } from 'react';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectUserLang } from 'actions/userActions';
import api from 'api';
import { registerRequest as registerAuthRequest } from 'authConfig';
import { LOGIN_STRATEGY, SESSION_NONCE } from 'constants/cookies';
import { LOCAL_STRATEGY } from 'constants/loginStrategies';
import useApi from 'hooks/useApi';
import useFeature from 'hooks/useFeature';
import usePageLogic from 'hooks/usePageLogic';
import useQuery from 'hooks/useQuery';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { addCookie, getCookie } from 'utils';

import LangSelector from 'components/common/LangSelector';
import Logo from 'components/common/Logo';
import MicrosoftButton from 'components/common/MicrosoftButton';
import ContinueWith from 'components/register/ContinueWithButton';
import RegisterForm from 'components/register/RegisterForm';

import useStyles from './styles';

const SchoolInvite = () => {
  const classes = useStyles();
  const { loaded } = usePageLogic(lang.register.header, false);
  const toast = useToast();
  const query = useQuery();
  const history = useHistory();
  const locale = useSelector(selectUserLang);
  const [registerRequest, loading] = useApi(api.register, true);
  const [addSpaceIdRequest] = useApi(api.addSpaceId);
  const [getSchoolFromToken] = useApi(api.getSchoolFromToken, true);
  const azureOauthToggle = useFeature(toggles.azureOauth);
  const token = query().get('token');
  const loginStrategy = getCookie(LOGIN_STRATEGY);
  const microsoftRegisterAuthRequest = {
    ...registerAuthRequest,
    state: token,
  };

  const [school, setSchool] = useState(null);

  useEffect(() => {
    getSchoolFromToken([token], null, ({ data }) => {
      if (data.status === 0) {
        setSchool(data.school);
        loaded();
        return;
      }

      toast.error(lang.oops);
    });
  }, [loaded, getSchoolFromToken, token, toast]);

  const handleRegister = (newUser) => {
    const payload = {
      ...newUser,
      locale: locale,
      loginStrategy: LOCAL_STRATEGY,
      schoolToken: token,
    };

    registerRequest([], payload, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          addCookie(SESSION_NONCE, data.sessionToken);
          addCookie(LOGIN_STRATEGY, LOCAL_STRATEGY);

          window.location = routes.tests.ref(data.spaceId, null);
          return;
        }

        if (data.status === 2) {
          toast.warning(lang.register.emailExistsError);
          return;
        }

        toast.error(lang.oops);
      }, 300);
    });
  };

  const handleAddSpaceId = () => {
    const payload = {
      schoolToken: token,
    };

    addSpaceIdRequest([], payload, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0 || data.status === 3) {
          window.location = routes.tests.ref(data.schoolId, null);
          return;
        }

        toast.error(lang.oops);
      }, 300);
    });
  };

  const handleLogout = () => {
    history.push(routes.logout.ref() + `?redirectUri=${routes.schoolInvite.ref()}?token=${token}`);
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.langSelectorContainer}>
        <LangSelector tipDirection="left" />
      </div>
      <div className={classes.cardContainer}>
        <div className={classes.card}>
          <div className={classes.logoContainer}>
            <Logo medium />
          </div>
          <div className={classes.header}>
            <div className={classes.title}>
              {lang.schoolInvite.header}
            </div>
            <span className={classes.subtitle1}>
              {lang.schoolInvite.description1}
            </span>
            <span className={classes.schoolName}>
              {school?.name}
              .
            </span>
            <Render when={loginStrategy === LOCAL_STRATEGY}>
              <span className={classes.subtitle2}>
                {lang.schoolInvite.description2}
              </span>
            </Render>
          </div>
          <Render when={!loginStrategy}>
            <Render when={azureOauthToggle}>
              <MicrosoftButton
                label={lang.register.registerWithMicrosoft}
                request={microsoftRegisterAuthRequest}
              />
              <div className={classes.loginStrategiesSeparator}>
                <div className={classes.separatorLine} />
                {lang.or.toUpperCase()}
                <div className={classes.separatorLine} />
              </div>
            </Render>
            <RegisterForm
              handleRegister={handleRegister}
              loading={loading}
            />
          </Render>
          <Render when={loginStrategy}>
            <div className={classes.continueContainer}>
              <ContinueWith
                onClick={handleAddSpaceId}
              />
            </div>
            <div className={classes.createAccountContainer}>
              {lang.schoolInvite.changeAccount1}
              <div
                onClick={() => handleLogout()}
                className={classes.registerLink}
              >
                {lang.schoolInvite.changeAccount2}
              </div>
            </div>
          </Render>
        </div>
      </div>
    </div>
  );
};

export default SchoolInvite;
