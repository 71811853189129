import React from 'react';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal as OLModal } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Button from '../Button';

import useStyles from './styles';

const Modal = ({ header, open, close, small, medium, large, children, center, transition, actions, className }) => {
  const classes = useStyles();
  const isDark = useSelector(state => state.page.isDark);

  const getActions = () => {
    if (actions) {
      const btnActions = actions.map(action => {
        return (
          <Button
            key={action.name}
            onClick={action.onClick}
            loading={action.loading}
            className={classes.modalBtn}
            red={action.color === 'red'}
            black={action.color === 'black'}
            sibling={actions.length > 1}
          >
            {action.name}
          </Button>
        );
      });

      return (
        <div className={classes.modalBtnContainer}>
          {btnActions}
        </div>
      );
    }
  };

  return (
    <OLModal
      open={open}
      close={close}
      center={center}
      small={small}
      medium={medium}
      large={large}
      dark={isDark}
      transition={transition}
      opacity={0.2}
      className={cx(classes.modal, className)}
    >
      <div className={classes.modalHeader}>
        <div>
          {header}
        </div>
        <FontAwesomeIcon
          icon={faCircleXmark}
          className={classes.modalCloseIcon}
          onClick={close}
        />
      </div>
      <div className={classes.modalBody}>
        {children}
        {getActions()}
      </div>
    </OLModal>
  );
};

Modal.propTypes = {
  header: PropTypes.string,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  children: PropTypes.any,
  center: PropTypes.bool,
  transition: PropTypes.bool,
  actions: PropTypes.array,
  className: PropTypes.string,
};

export default Modal;
