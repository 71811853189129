import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  pageContainer: {
    minHeight: '100vh',
    display: 'flex',
  },
  langSelectorContainer: {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    backgroundColor: theme.midnightGreen,
    width: '50%',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
  },
  cardContainer: {
    width: '100%',
  },
  recoverContainer: {
    width: '100%',
    margin: '20px 20%',
    padding: '30px',
    boxShadow: `0 0 5px ${theme.shadowColor}`,
    backgroundColor: theme.backgroundColor,
    borderRadius: '18px',
  },
  recoverHeader: {
    paddingTop: '10px',
    marginBottom: '24px',
  },
  recoverTitle: {
    fontSize: '24px',
  },
  recoverSubtitle: {
    fontSize: '15px',
    color: theme.textColor2,
  },
  input: {
    marginTop: '20px',
  },
  button: {
    margin: '20px 0',
    marginBottom: '5px',
    border: 'none',
  },
  forgotPassword: {
    margin: '10px 0',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  separator: {
    margin: '20px 25%',
    borderBottom: `1px solid ${theme.textColor2}`,
  },
  createAccountContainer: {
    textAlign: 'center',
    fontSize: '14px',
  },
  registerLink: {
    marginLeft: '5px',
    color: '#83cafd',
  },
  loginContainer: {
    textAlign: 'center',
    fontSize: '14px',
  },
  loginLink: {
    color: '#83cafd',
  },
  [`@media (max-width: ${theme.md}px)`]: {
    pageContainer: {
      flexDirection: 'column',
      backgroundColor: theme.midnightGreen,
    },
    logoContainer: {
      alignItems: 'start',
      width: '100%',
      paddingTop: '7vh',
    },
    formContainer: {
      width: '100%',
      marginTop: '3vh',
    },
    recoverContainer: {
      margin: '20px 10%',
    },
  },
}));
