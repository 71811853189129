import React, { Fragment } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toggle } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserPlan } from 'actions/userActions';
import { FREE, PREMIUM, SCHOOLS } from 'constants/plans';
import lang from 'lang';
import routes from 'routes';

import Button from 'components/common/Button';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const PlanFeatureCard = ({ plan, currentUserPlan, setCurrentUserPlan, monthlyPayment, setMonthlyPayment, toggleCancelSubscription, loading }) => {
  const classes = useStyles();
  const userPlan = useSelector(selectUserPlan);
  const planDueDate = new Date(userPlan.nextPayment);
  const planDueDateDay = planDueDate.getDate();
  const planDueDateMonth = planDueDate.getMonth() + 1;
  const planDueDateYear = planDueDate.getFullYear();

  const getPlanType = () => {
    switch (plan.name) {
      case FREE:
        return lang.profile.plans.basic;
      case PREMIUM:
        return lang.profile.plans.premium;
      case SCHOOLS:
        return lang.profile.plans.schools;
      default:
        break;
    }
  };

  const getPriceLabel = () => {
    switch (plan.name) {
      case FREE:
        return lang.profile.plans.free;
      case PREMIUM:
        if (((monthlyPayment && plan.monthlyDiscount) || (!monthlyPayment && plan.yearlyDiscount)) && plan?.coupons.find(coupon => coupon.primary)) {
          const price = monthlyPayment ? plan.monthlyPrice : plan.yearlyPrice;
          const discount = 1 - (monthlyPayment ? plan.monthlyDiscount : plan.yearlyDiscount);
          return (
            <div className={classes.pricesWrapper}>
              <div className={classes.originalPrice}>
                {price + '€'}
              </div>
              <div className={classes.discountedPrice}>
                {(price * discount).toFixed(2) + '€'}
              </div>
            </div>
          );
        } else {
          if (monthlyPayment) {
            return plan.monthlyPrice + '€';
          } if (!monthlyPayment) {
            return plan.yearlyPrice + '€';
          }
        }
        break;
      case SCHOOLS:
        return lang.profile.plans.onRequest;
      default:
        break;
    }
  };

  const getPlanTarget = () => {
    switch (plan.name) {
      case FREE:
        return lang.profile.plans.forTeachers;
      case PREMIUM:
        return lang.profile.plans.forTeachers;
      case SCHOOLS:
        return lang.profile.plans.forSchools;
      default:
        break;
    }
  };

  const getButtonLabel = () => {
    switch (plan.name) {
      case FREE:
        return lang.profile.plans.switchToPlan;
      case PREMIUM:
        return lang.profile.plans.switchToPlan;
      case SCHOOLS:
        return lang.profile.plans.recommendSchool;
      default:
        break;
    }
  };

  const getButtonFunction = () => {
    if (plan.name === SCHOOLS) {
      window.open(routes.recommendSchool.ref(), '_blank');
    } else {
      setCurrentUserPlan(plan);
    }
  };

  const _toggleCancelSubscription = () => {
    if (loading) {
      return;
    }
    toggleCancelSubscription();
  };

  return (
    <div className={classes.wrapper}>
      <Render when={plan.name === PREMIUM}>
        <div className={classes.compactedWrapper}>
          <div className={classes.compactedText}>
            {lang.profile.plans.yearly}
          </div>
          <Toggle
            className={classes.toggle}
            checked={monthlyPayment}
            onChange={() => setMonthlyPayment(!monthlyPayment)}
          />
          <div className={classes.compactedText}>
            {lang.profile.plans.monthly}
          </div>
        </div>
      </Render>
      <div className={cx(classes.card, { free: plan.name === FREE, premium: plan.name === PREMIUM, schools: plan.name === SCHOOLS, selected: currentUserPlan.name === plan.name })}>
        <div className={classes.header}>
          <div className={cx(classes.planType, { free: plan.name === FREE, premium: plan.name === PREMIUM, schools: plan.name === SCHOOLS })}>
            {getPlanType()}
          </div>
          <div className={classes.planTarget}>
            {getPlanTarget()}
          </div>
        </div>
        <div className={classes.planPrice}>
          {getPriceLabel()}
          <Render when={plan.name === PREMIUM}>
            <div className={classes.perMonth}>
              {`/${lang.month} (${lang.profile.plans.includedTax})`}
            </div>
          </Render>
        </div>
        <Render when={plan.name === PREMIUM && !monthlyPayment}>
          <div className={classes.yearlyExtraText}>
            {`(${lang.profile.plans.billedYearly})`}
          </div>
        </Render>
        <div className={classes.planDescription}>
          {lang.profile.plans.planDescription[plan.name]}
        </div>
        <Render when={userPlan.name === plan.name && plan.name === PREMIUM}>
          <Tooltip tip={userPlan.active ? lang.profile.plans.cancelTip(`${planDueDateDay}/${planDueDateMonth}/${planDueDateYear}`) : ''}>
            <Button
              className={cx(classes.cancelButton, { cancel: userPlan.active })}
              sibling
              onClick={_toggleCancelSubscription}
              loading={loading}
            >
              {userPlan.active ? lang.profile.plans.cancelSubscription : lang.profile.plans.continueSubscription}
            </Button>
          </Tooltip>
        </Render>
        <Render when={currentUserPlan.name !== plan.name && currentUserPlan.name === FREE}>
          <Button
            className={cx(classes.button, { free: plan.name === FREE, premium: plan.name === PREMIUM, schools: plan.name === SCHOOLS })}
            sibling
            onClick={getButtonFunction}
          >
            {getButtonLabel()}
          </Button>
        </Render>
        <div className={classes.featuresWrapper}>
          <Render when={plan.name === SCHOOLS}>
            {Object.keys(lang.profile.plans.schoolsFeatures).map(feature => (
              <Fragment key={feature}>
                <div>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={classes.icon}
                  />
                </div>
                <div className={classes.feature}>
                  {lang.profile.plans.schoolsFeatures[feature]}
                </div>
              </Fragment>
            ))}
          </Render>
          {plan.features.map(feature => (
            <Fragment key={feature.name + plan.name}>
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  className={classes.icon}
                />
              </div>
              <div className={classes.feature}>
                {lang.features[plan.name][feature.name](feature.maxUsages)}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

PlanFeatureCard.propTypes = {
  plan: PropTypes.object,
  currentUserPlan: PropTypes.object,
  setCurrentUserPlan: PropTypes.func,
  monthlyPayment: PropTypes.bool,
  setMonthlyPayment: PropTypes.func,
  toggleCancelSubscription: PropTypes.func,
  loading: PropTypes.bool,
};

export default PlanFeatureCard;
