import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  headerContainer: {
    padding: '30px 30px 15px 30px',
    backgroundColor: theme.backgroundColor2,
  },
  headerSplitter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  header: {
    height: '1.25em',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '22px',
    fontWeight: 600,
  },
  description: {
    display: 'flex',
    color: theme.textColor5,
    paddingTop: '10px',
  },
  descriptionIcon: {
    marginRight: '5px',
    color: theme.textColor5,
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  headerActions: {
    display: 'flex',
    marginRight: '40px',
  },
  actionWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '30px',
    opacity: 0.6,
    fontSize: '20px',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      opacity: 0.9,
    },
    '&.active': {
      color: theme.successColor,
    },
    '&.disabled': {
      opacity: 0.3,
      cursor: 'default',
    },
  },
  actionTitle: {
    fontSize: '12px',
    marginTop: '5px',
  },
  extraActions: {
    height: '37px',
    width: '18px',
    fontSize: '20px',
    textAlign: 'center',
    marginLeft: '30px',
    opacity: 0.6,
    '&:hover': {
      opacity: 0.9,
    },
    '& svg': {
      marginTop: '9px',
    },
  },
  extraActionsDropdownMenu: {
    zIndex: '2',
    borderRadius: '0px',
    border: `1px solid ${theme.cardBorder} !important`,
  },
  extraActionsDropdownItem: {
    margin: 'unset !important',
    borderBottom: 'unset !important',
    backgroundColor: theme.cardBackground,
    '&:hover': {
      backgroundColor: theme.cardColor2,
      cursor: 'pointer',
    },
  },

  [`@media (max-width: ${theme.xs}px)`]: {
    headerContainer: {
      padding: '20px',
    },
    headerSplitter: {
      flexDirection: 'column',
    },
    headerRight: {
      marginTop: '20px',
    },
    header: {
      fontSize: '28px',
    },
  },
}));
