import React, { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { linkMicrosoftAccount, selectUserLang } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import lang from 'lang';
import routes from 'routes';

import Loading from 'components/common/Loading';

const MicrosoftLinkRedirect = () => {
  const { inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0]);
  const history = useHistory();
  const locale = useSelector(selectUserLang);
  const toast = useToast();
  const [linkWithMicrosoftRequest] = useApi(api.linkWithMicrosoft);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      const payload = {
        oid: account.idTokenClaims.oid,
        email: account.username,
      };

      linkWithMicrosoftRequest([], payload, ({ data }) => {
        if (data.status === 0) {
          toast.success(lang.profile.security.accountLinkedWithMicrosoftSuccess);
          dispatch(linkMicrosoftAccount(account.username));
          history.push(routes.profile.ref());
        } else if (data.status === 2) {
          toast.warning(lang.profile.security.emailAlreadyInUse);
          history.push(routes.profile.ref());
        } else {
          toast.error(lang.oops);
          history.push(routes.profile.ref());
        }
      });
    } else if (!isAuthenticated && inProgress === InteractionStatus.None) {
      toast.error(lang.oops);
      history.push(routes.profile.ref());
    }
  }, [isAuthenticated, inProgress, account, history, locale, toast, linkWithMicrosoftRequest, dispatch]);

  return (
    <Loading
      active
      fullPage
    />
  );
};

export default MicrosoftLinkRedirect;
