export default (theme) => ({
  headerItem: {
    paddingBottom: '3px',
    fontSize: '13px',
    color: theme.textColor2,
  },
  hint: {
    fontSize: '12px',
    color: theme.textColor3,
  },
  errorsContainer: {
    margin: ['10px', '15px', '0', '15px'],
    fontSize: '14px',
    color: theme.errorColor,
    '& > * + *': {
      marginTop: '5px',
    },
  },
  datepicker: {
    color: theme.textColor,
    borderRadius: '18px',
  },
  input: {
    border: 'none',
    borderRadius: '18px',
    backgroundColor: theme.inputBackground,
  },
});
