import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

import routes from 'routes';

const PrivateRoute = ({ exact, path, children }) => {
  const isAuthenticated = useSelector(state => state.user.loggedIn);
  const appLoading = useSelector(state => state.page.appLoading);
  const history = useHistory();

  if (!isAuthenticated && !appLoading) {
    history.push(routes.home.ref());
    return null;
  }

  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string,
  exact: PropTypes.bool,
  children: PropTypes.any,
};

export default PrivateRoute;
