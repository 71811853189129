import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import useStyles from 'hooks/useStyles';
import { getFileIcon } from 'utils';

import styles from './styles';

const Dropzone = ({ placeholder, value, minSize, maxSize, invalid, disabled, onDrop, multiple }) => {
  const classes = useStyles(styles);
  const { getRootProps, getInputProps } = useDropzone({ multiple: multiple || false, onDrop, disabled, minSize, maxSize });

  return (
    <Fragment>
      <div
        {...getRootProps({
          className: cx(classes.dropzone, { invalid, disabled }),
        })}
      >
        <input
          {...getInputProps()}
        />
        {placeholder}
      </div>
      <Row>
        {value.map(file => (
          <Col
            key={file.name}
            className={classes.file}
            xl={3}
          >
            <FontAwesomeIcon
              icon={getFileIcon(file.name.split('.').pop())}
              className={classes.fileIcon}
            />
            <div className={classes.fileName}>
              {file.name}
            </div>
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

Dropzone.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  onDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

export default Dropzone;
