import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  card: {
    backgroundColor: theme.backgroundColor,
    width: '100% !important',
    height: 'fit-content',
  },
  cardSelectorWrapper: {
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  button: {
    '&.warning': {
      backgroundColor: theme.crayola,
      color: '#000',
    },
  },
}));
