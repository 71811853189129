import React from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar as OLNavbar } from '@intuitivo/outline';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/common/Button';
import Logo from 'components/common/Logo';

import useStyles from './styles';

const Navbar = ({ open, setOpen }) => {
  const classes = useStyles();

  return (
    <>
      <OLNavbar className={cx(classes.navbar, { open })}>
        <div className={classes.mobileLogoWrapper}>
          <Logo small />
        </div>
        <Button className={classes.hamburger} onClick={() => setOpen(true)} >
          <FontAwesomeIcon className={classes.hamburgerIcon} icon={faBars} />
        </Button>
      </OLNavbar>
      <div className={cx(classes.overlay, { open })} onClick={() => setOpen(false)} />
    </>
  );
};

Navbar.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default Navbar;
