import React, { useEffect, useState } from 'react';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectPaymentMethod, selectUserLang, selectUserPlan, setPaymentMethod, setPlan, setPlanActive } from 'actions/userActions';
import api from 'api';
import { FREE, PREMIUM } from 'constants/plans';
import useApi from 'hooks/useApi';
import useInput from 'hooks/useInput';
import useQuery from 'hooks/useQuery';
import lang from 'lang';

import PlanFeatureCard from '../PlanFeatureCard';
import Button from 'components/common/Button';
import Input from 'components/common/input/Input';
import Modal from 'components/common/Modal';
import Tooltip from 'components/common/Tooltip';

import { PAYMENT, PLANS } from './constants';
import useStyles from './styles';

const PlansCardModal = ({ open, close }) => {
  const classes = useStyles();
  const query = useQuery();
  const [getPlansRequest] = useApi(api.getPlans);
  const [upgradeUserPlanRequest] = useApi(api.upgradeUserPlan);
  const [getPaymentMethodRequest] = useApi(api.getPaymentMethod);
  const [toggleCancelSubscriptionRequest] = useApi(api.toggleCancelSubscription);
  const [getPromoCodeRequest] = useApi(api.getPromoCode);
  const userPlan = useSelector(selectUserPlan);
  const [currentUserPlan, setCurrentUserPlan] = useState(userPlan);
  const [monthlyPayment, setMonthlyPayment] = useState(query().get('type') === 'monthly' || userPlan.subscriptionType === 'monthly');
  const [plans, setPlans] = useState([]);
  const [validPromoCode, setValidPromoCode] = useState(null);
  const [promoCode, setPromoCode, promoCodeErrors, setPromoCodeErrors] = useInput('');

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [modalState, setModalState] = useState(PLANS);
  const locale = useSelector(selectUserLang);
  const paymentMethod = useSelector(selectPaymentMethod);

  const _close = (planToClose) => {
    if (loading) {
      return;
    }
    setTimeout(() => {
      setCurrentUserPlan(planToClose.name ? planToClose : userPlan);
      setModalState(PLANS);
    }, 200);
    close();
  };

  useEffect(() => {
    getPlansRequest([], null, ({ data }) => {
      if (data.status === 0) {
        setPlans(data.plans);
        const foundPlan = data.plans.find(el => el.name === query().get('plan'));
        if (foundPlan) {
          setCurrentUserPlan(foundPlan);
        }
        if (foundPlan && foundPlan.name !== userPlan.name && foundPlan.name === PREMIUM && query().get('type')) {
          setModalState(PAYMENT);
        }
      }
    });
  }, [getPlansRequest, userPlan, query]);

  const editUserPlan = async () => {
    setLoading(true);

    if (currentUserPlan.name === PREMIUM && !paymentMethod) {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setLoading(false);
        return;
      }
    }

    upgradeUserPlanRequest([], { planId: currentUserPlan.id, monthlyPayment, promoCodeId: validPromoCode?.id }, async ({ data }) => {
      if (data.status === 0) {
        if (data.clientSecret) {
          const confirmIntent = data.type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

          const result = await confirmIntent({
            elements: paymentMethod ? undefined : elements,
            clientSecret: data.clientSecret,
            redirect: 'if_required',
            confirmParams: {
              return_url: window.location.href,
            },
          });

          if (result.error) {
            setLoading(false);
            toast.error(result.error.message);
            upgradeUserPlanRequest([], { planId: userPlan.id }, async ({ data }) => {
              if (data.status !== 0) {
                toast.error(lang.oops);
              }
            });
            return;
          } else if (result.paymentIntent) {
            getPaymentMethodRequest([], null, ({ data }) => {
              if (data.status === 0) {
                dispatch(setPaymentMethod(data.paymentMethod));
              }
            });
          }
        }

        setLoading(false);
        setTimeout(() => {
          dispatch(setPlan(data.plan));
          toast.success(lang.changesSaved);
        }, 200);

      } else {
        setLoading(false);
        toast.error(lang.oops);
      }

      _close(currentUserPlan);

      window.history.replaceState(null, null, window.location.pathname);
    });
  };

  const toggleCancelSubscription = () => {
    setLoading(true);
    toggleCancelSubscriptionRequest([], null, ({ data }) => {
      if (data.status === 0) {
        dispatch(setPlanActive(!userPlan.active));
        toast.success(lang.changesSaved);
      } else {
        toast.error(lang.oops);
      }
      setLoading(false);
    });
  };

  const validatePromoCode = (event) => {
    event.preventDefault();

    if (promoCode.trim() === '') {
      return;
    }

    setLoading(true);
    getPromoCodeRequest([promoCode], null, ({ data }) => {
      if (data.status === 0) {
        setValidPromoCode(data.promoCode);
        setPromoCodeErrors([]);
        setPromoCode('');
      } else if (data.status === 3) {
        setPromoCodeErrors([lang.profile.plans.promoCodeInvalidError]);
      } else {
        toast.error(lang.oops);
      }
      setLoading(false);
    });
  };

  const confirm = () => {
    if (currentUserPlan.name === userPlan.name) {
      _close();
    } else if (userPlan.name === FREE && currentUserPlan.name === PREMIUM && modalState === PLANS && !paymentMethod) {
      setModalState(PAYMENT);
    } else if (modalState === PAYMENT || currentUserPlan.name === FREE || (currentUserPlan.name === PREMIUM && paymentMethod)) {
      editUserPlan();
    }
  };

  const getPrice = () => {
    let discount = 0;
    const price = monthlyPayment ? currentUserPlan?.monthlyPrice : currentUserPlan?.yearlyPrice;
    if (currentUserPlan.monthlyDiscount || currentUserPlan.yearlyDiscount) {
      discount = monthlyPayment ? currentUserPlan?.monthlyDiscount : currentUserPlan?.yearlyDiscount;
    } else if (validPromoCode) {
      discount = validPromoCode.percent_off / 100;
    }
    return (price * (1 - discount)).toFixed(2);
  };

  const getPlanPricing = (billing, tax) => {
    if (monthlyPayment) {
      return getPrice() + '€ ' + (billing ? lang.profile.plans.billedMonthly : '') + (tax ? ` (${(getPrice() * 0.23).toFixed(2)}€ ${lang.profile.plans.taxInfo})` : '');
    } else {
      return (getPrice() * 12) + '€ ' + (billing ? lang.profile.plans.billedYearly : '') + (tax ? ` (${(getPrice() * 12 * 0.23).toFixed(2)}€ ${lang.profile.plans.taxInfo})` : '');
    }
  };

  const getDiscountText = (tooltip) => {
    const plan = plans.find(plan => plan.name === PREMIUM);
    const yearlyDiscount = plan?.coupons.find(coupon => coupon.primary);
    const limitDate = new Date(yearlyDiscount?.redeem_by * 1000);
    let limitDateDay, limitDateMonth, limitDateYear, limitDateString;
    if (limitDate) {
      const date = new Date(limitDate);
      limitDateDay = date.getDate();
      limitDateMonth = date.getMonth() + 1;
      limitDateYear = date.getFullYear();
      limitDateString = `${limitDateDay}/${limitDateMonth}/${limitDateYear}`;
    }

    const monthlyDiscount = plan?.monthlyDiscount;
    if (tooltip) {
      return lang.profile.plans.discountTooltip(yearlyDiscount?.metadata[locale], limitDateString, yearlyDiscount?.percent_off, monthlyDiscount * 100);
    }
    return (
      <span>
        {lang.profile.plans.discountPrimaryText(yearlyDiscount?.metadata[locale])}
        <span className={classes.discountHighlightText}>
          {' ' + lang.profile.plans.discountHighlightText(limitDateString)}
        </span>
      </span>
    );
  };

  const actions = [
    {
      name: lang.confirm + (modalState === PAYMENT ? ` ${lang.profile.plans.andPay} ${getPlanPricing(false, false)}` : ''),
      color: 'red',
      onClick: confirm,
      loading: loading,
    },
    {
      name: lang.cancel,
      color: 'black',
      onClick: _close,
    },
  ];

  if (!stripe || !elements) {
    return false;
  }

  return (
    <Modal
      open={open}
      close={_close}
      header={lang.profile.plans.plans}
      actions={userPlan.name === FREE ? actions : []}
      className={classes.plansModal}
      center
      transition
      large
    >
      <Render when={modalState === PLANS}>
        <Render when={plans.find(plan => plan.name === PREMIUM)?.coupons.find(coupon => coupon.primary)}>
          <div className={classes.discountText}>
            <span>
              {getDiscountText()}
            </span>
            <Tooltip tip={getDiscountText(true)}>
              <FontAwesomeIcon className={classes.icon} icon={faCircleQuestion} />
            </Tooltip>
          </div>
        </Render>
        <div className={classes.plansWrap}>
          {plans.map(plan => (
            <PlanFeatureCard
              plan={plan}
              key={plan.id}
              currentUserPlan={currentUserPlan}
              setCurrentUserPlan={setCurrentUserPlan}
              monthlyPayment={monthlyPayment}
              setMonthlyPayment={setMonthlyPayment}
              toggleCancelSubscription={toggleCancelSubscription}
              loading={loading}
            />
          ))}
        </div>
      </Render>
      <Render when={modalState === PAYMENT}>
        <div className={classes.wrapper}>
          <PaymentElement
            id="payment-element"
            className={classes.paymentElement}
            options={{ layout: { type: 'tabs', defaultCollapsed: false } }}
          />
          <div className={classes.paymentSummary}>
            <div className={classes.summaryTitle}>
              {lang.profile.plans.yourSubscription}
            </div>
            <div className={classes.subscriptionInfo}>
              <div className={classes.planName}>
                {currentUserPlan.name[0].toUpperCase() + currentUserPlan.name.slice(1)}
                {' (' + (monthlyPayment ? lang.profile.plans.monthly : lang.profile.plans.yearly) + ')'}
              </div>
              <div className={classes.planPricing}>
                {getPlanPricing(true)}
              </div>
            </div>
            <Render when={validPromoCode}>
              <div className={classes.promoCodeInfo}>
                <div>
                  {`${lang.profile.plans.promoCode} (${validPromoCode?.code})`}
                </div>
                <div className={classes.percentOff}>
                  {`-${validPromoCode ? validPromoCode.percent_off : ''}%`}
                  <FontAwesomeIcon className={classes.removeCodeIcon} icon={faTimes} onClick={() => setValidPromoCode(null)} />
                </div>
              </div>
            </Render>
            <hr className={classes.line} />
            <div className={classes.total}>
              <div>
                {lang.profile.plans.total}
              </div>
              <div>
                {getPlanPricing(false, true)}
              </div>
            </div>
            <Render when={!validPromoCode}>
              <form className={classes.promoCodeWrapper} onSubmit={validatePromoCode}>
                <Input
                  type="text"
                  placeholder={lang.profile.plans.promoCodePlaceholder}
                  className={classes.promoCodeInput}
                  value={promoCode}
                  errors={promoCodeErrors}
                  invalid={promoCodeErrors.length !== 0}
                  onChange={event => setPromoCode(event.target.value)}
                />
                <Button
                  type="submit"
                  className={classes.promoButton}
                  sibling
                  loading={loading}
                >
                  {lang.profile.plans.apply}
                </Button>
              </form>
            </Render>
          </div>
        </div>
      </Render>
    </Modal>
  );
};

PlansCardModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default PlansCardModal;
