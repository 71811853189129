import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserData } from 'actions/userActions';
import lang from 'lang';

import Button from 'components/common/Button';
import Logo from 'components/common/Logo';

import useStyles from './styles';

const ContinueWithButton = ({ onClick }) => {
  const classes = useStyles();
  const { fullName } = useSelector(selectUserData);

  return (
    <Button
      sibling
      onClick={onClick}
      className={classes.microsoftButton}
    >
      <Logo
        minimal
      />
      <div className={classes.continueWith}>
        {lang.schoolInvite.continueWith}
      </div>
      <div className={classes.fullName}>
        {fullName}
      </div>
    </Button>
  );
};

ContinueWithButton.propTypes = {
  onClick: PropTypes.func,
};

export default ContinueWithButton;
