/**
 * Action constants
 */
const APP_LOADED = 'APP_LOADED';
const PAGE_ERROR = 'PAGE_ERROR';
const TOGGLE_DARK = 'TOGGLE_DARK';
const SHOW_LAYOUT = 'SHOW_LAYOUT';

/**
 * Action creators
 */
const appLoaded = () => ({
  type: APP_LOADED,
});

const pageError = (code) => ({
  type: PAGE_ERROR,
  code: code,
});

const toggleDark = () => {
  if (localStorage.getItem('isDark') === null) {
    localStorage.setItem('isDark', 'maybe');
  } else {
    localStorage.removeItem('isDark');
  }

  return {
    type: TOGGLE_DARK,
  };
};

const showLayout = (layout) => ({
  type: SHOW_LAYOUT,
  layout: layout,
});

export {
  APP_LOADED,
  PAGE_ERROR,
  TOGGLE_DARK,
  SHOW_LAYOUT,
  appLoaded,
  pageError,
  toggleDark,
  showLayout,
};
