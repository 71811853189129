import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import lang from 'lang';

import FilterPool from 'components/common/filter-selector/FilterPool';

import useStyles from './styles';

const SubjectsSelector = ({ newPreferences, setNewPreferences, subjects }) => {
  const classes = useStyles();

  const toggleSelectedSubject = useCallback((selectedSubject) => {
    const isActive = !!newPreferences.subjects.find(subject => subject.id === selectedSubject.id);

    let newSelectedSubjects;
    if (isActive) {
      newSelectedSubjects = newPreferences.subjects.filter(subject => subject.id !== selectedSubject.id);
    } else {
      newSelectedSubjects = [...newPreferences.subjects, selectedSubject];
    }

    setNewPreferences({
      ...newPreferences,
      subjects: newSelectedSubjects,
    });
  }, [newPreferences, setNewPreferences]);

  const newSubjects = useMemo(() => subjects?.map(subject => ({
    ...subject,
    type: 'action',
    active: !!newPreferences.subjects.find(el => el.id === subject.id),
    color: newPreferences.subjects.find(el => el.id === subject.id) ? subject.color : null,
    hoverColor: newPreferences.subjects.find(el => el.id === subject.id) ? subject.hoverColor : null,
    action: () => toggleSelectedSubject(subject),
  })), [newPreferences.subjects, subjects, toggleSelectedSubject]);

  return (
    <div className={classes.areas}>
      <div className={classes.label}>
        {lang.profile.profilePreferences.subjects}
      </div>
      <FilterPool
        poolItems={newSubjects ? newSubjects : []}
        selectable={true}
      />
    </div>
  );
};

SubjectsSelector.propTypes = {
  newPreferences: PropTypes.object,
  setNewPreferences: PropTypes.func,
  subjects: PropTypes.array,
};

export default SubjectsSelector;
