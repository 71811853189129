import React from 'react';
import { faChild, faComments, faFileAlt, faGear, faGraduationCap, faInfoCircle, faMapMarkedAlt, faPencilRuler, faQuestionCircle, faRotate, faTable, faUser, faUsers, faFileCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar as OLNavbar } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { pageError } from 'actions/pageActions';
import { selectIsAdmin, selectIsSuperadmin, selectUserIsStudent, selectUserIsTeacher } from 'actions/userActions';
import { LOCAL } from 'constants/environments';
import { PREMIUM } from 'constants/plans';
import { TEACHER } from 'constants/roles';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import LogoutButton from '../LogoutButton';
import SchoolDropdown from '../SchoolDropdown';
import ALink from 'components/common/ALink';
import Button from 'components/common/Button';
import LangSelector from 'components/common/LangSelector';
import Logo from 'components/common/Logo';
import PlansPill from 'components/common/plans/PlansPill';
import Tooltip from 'components/common/Tooltip';

import { ReactComponent as LogoIAVE } from './logo_iave.module.svg';
import useStyles from './styles';

const Sidebar = ({ open, setOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector(state => state.user.data);
  const isAdmin = useSelector(selectIsAdmin);
  const isSuperadmin = useSelector(selectIsSuperadmin);
  const isTeacher = useSelector(selectUserIsTeacher);
  const isStudent = useSelector(selectUserIsStudent);
  const dispatch = useDispatch();
  const exploreToggle = useFeature(toggles.explore);
  const explorePageToggle = useFeature(toggles.explorePage);
  const iaveToggle = useFeature(toggles.iave);
  const plansToggle = useFeature(toggles.plans);
  const adminGroupsToggle = useFeature(toggles.adminGroups);

  const page = history.location.pathname.split('/').pop().split('?')[0];

  const changePage = (route) => {
    setOpen(false);
    dispatch(pageError(null));

    const spaceId = user.spaceIdPreference ?? history.location.pathname.split('/')[2];
    const schools = user.schools.map(school => school.id);
    const spaces = user.hasPersonalSpace ? ['p', ...schools] : schools;

    if (route === page) {
      if (!spaces.includes(spaceId)) {
        window.location = routes[route].ref(spaces[0]);
        return;
      }
      return;
    }

    if (spaceId) {
      window.location = routes[route].ref(spaceId);
    } else {
      window.location = routes[route].ref(spaces[0]);
    }
  };

  const handleAccountRedirect = () => {
    window.location = routes.profile.ref();
  };

  const pageLinks = [
    {
      icon: faMapMarkedAlt,
      label: lang.appKeywords.explore,
      page: 'explore',
      hide: !isTeacher || !explorePageToggle || !exploreToggle || isAdmin || isSuperadmin,
    },
    {
      icon: faFileAlt,
      label: lang.appKeywords.assessments,
      page: 'tests',
      hide: !isTeacher && !isStudent,
    },
    {
      icon: faUser,
      label: lang.appKeywords.teachers,
      page: 'teachers',
      hide: !isSuperadmin,
    },
    {
      icon: faChild,
      label: lang.appKeywords.students,
      page: 'students',
      hide: (!isAdmin && !isSuperadmin) || !iaveToggle,
    },
    {
      icon: faPencilRuler,
      label: lang.appKeywords.exercises,
      page: 'exercises',
      hide: !isTeacher,
    },
    {
      icon: faTable,
      label: lang.appKeywords.rubrics,
      page: 'rubrics',
      hide: !isTeacher,
    },
    {
      icon: faUsers,
      label: lang.appKeywords.groups,
      page: 'groups',
      hide: !isTeacher && (!isSuperadmin || !adminGroupsToggle),
    },
    {
      icon: faInfoCircle,
      label: lang.appKeywords.events,
      page: 'events',
      hide: !iaveToggle || !isSuperadmin,
    },
    {
      icon: faFileCircleCheck,
      label: lang.appKeywords.exams,
      page: 'exams',
      hide: !iaveToggle || !isSuperadmin,
      isExternal: true,
    },
    {
      icon: faRotate,
      label: lang.appKeywords.synchronization,
      page: 'synchronization',
      hide: !isAdmin || process.env.REACT_APP_NODE_ENV !== LOCAL,
    },
  ];

  return (
    <OLNavbar className={cx(classes.sidebar, { open })}>
      <div className={classes.logoWrapper}>
        {iaveToggle ?
          <LogoIAVE className={classes.iaveLogo} />
          :
          <Logo small />
        }
        <Render when={plansToggle}>
          <Render when={user.role === TEACHER && user.plan && user.plan.name === PREMIUM}>
            <PlansPill
              message={user.spaceIdPreference !== 'p' ? lang.profile.plans.schools : lang.profile.plans.premium}
              isSchool={user.spaceIdPreference !== 'p'}
            />
          </Render>
          <Render when={user.role === TEACHER && user.plan && user.plan.name !== PREMIUM}>
            <ALink
              href={routes.profile.ref(PREMIUM)}
            >
              <PlansPill
                message={user.spaceIdPreference !== 'p' ? lang.profile.plans.schools : lang.profile.plans.getPremium}
                getPremium={user.spaceIdPreference === 'p'}
                isSchool={user.spaceIdPreference !== 'p'}
              />
            </ALink>
          </Render>
        </Render>
      </div>
      {pageLinks.map((pageLink) => (
        <Render
          key={pageLink.label}
          when={!pageLink.hide}
        >
          <div
            className={cx(classes.pageLink, 'otl-noselect', { selected: page === pageLink.page })}
            onClick={() => changePage(pageLink.page)}
          >
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon
                icon={pageLink.icon}
                className={classes.pageIcon}
              />
            </div>
            <div className={classes.pageLabel}>
              {pageLink.label}
            </div>
          </div>
        </Render>
      ))}
      <Render when={isTeacher || isAdmin || isSuperadmin}>
        <div className={classes.separator}></div>
        <ALink
          href={routes.feedback.ref()}
          target="_blank"
        >
          <div
            className={cx(classes.pageLink, 'otl-noselect')}
          >
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon
                icon={faComments}
                className={classes.pageIcon}
              />
            </div>
            <div className={classes.pageLabel}>
              {lang.feedback}
            </div>
          </div>
        </ALink>
        <ALink
          href={routes.help.ref()}
          target="_blank"
        >
          <div
            className={cx(classes.pageLink, 'otl-noselect')}
          >
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className={classes.pageIcon}
              />
            </div>
            <div className={classes.pageLabel}>
              {lang.help}
            </div>
          </div>
        </ALink>
        <ALink
          href={routes.tutorial.ref()}
          target="_blank"
        >
          <div
            className={cx(classes.pageLink, 'otl-noselect')}
          >
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon
                icon={faGraduationCap}
                className={classes.pageIcon}
              />
            </div>
            <div className={classes.pageLabel}>
              {lang.tutorial}
            </div>
          </div>
        </ALink>
      </Render>
      <div className={classes.profileWrapper}>
        <div className={classes.userName}>
          {user.fullName && user.fullName.split(' ').length > 1 ? `${user.fullName.split(' ')[0]} ${user.fullName.split(' ')[user.fullName.split(' ').length - 1]}` : user.fullName}
        </div>
        <div className={classes.userEmail}>
          {user.email}
        </div>
        <Render when={isTeacher || isAdmin || isSuperadmin}>
          <SchoolDropdown />
        </Render>
        <div className={classes.buttons}>
          <LangSelector />
          <Tooltip tip={lang.account}>
            <Button className={cx(classes.button, classes.account)} onClick={handleAccountRedirect}>
              <FontAwesomeIcon icon={faGear} />
            </Button>
          </Tooltip>
          <LogoutButton />
        </div>
      </div>
    </OLNavbar>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default Sidebar;
